import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { previousPage, nextPage } from "./queueActions.js";

const ChangePage = () => {
  const dispatch = useDispatch();
  const page = useSelector(state => state.queue.page);
  const pageCount = useSelector(state => state.queue.pageCount);

  const toPrevPage = useCallback(() => {
    dispatch(previousPage());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const toNextPage = useCallback(() => {
    dispatch(nextPage());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <>
      <button
        style={page <= 1 ? { visibility: "hidden" } : {}}
        onClick={toPrevPage}
        className="button-link"
        id="btn-prev"
      >
        Prev
      </button>
      <button
        style={page >= pageCount ? { visibility: "hidden" } : {}}
        onClick={toNextPage}
        className="button-link"
        id="btn-next"
      >
        Next
      </button>
    </>
  );
};

export default ChangePage;
