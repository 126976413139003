export const GET_LINKED_POST = "GET_LINKED_POST";
export const GET_LINKED_POST_FULFILLED = `${GET_LINKED_POST}_FULFILLED`;
export const GET_LINKED_POST_REJECTED = `${GET_LINKED_POST}_REJECTED`;

const urlRegex = /\/comments\/(.{6})/;
export function getLinkedPost(torPost) {
  return dispatch => {
    const postString = urlRegex.exec(torPost.url)[1];
    dispatch({
      type: GET_LINKED_POST,
      payload: new Promise((resolve, reject) => {
        torPost._r
          .getSubmission(postString)
          .fetch()
          .then(post => resolve(post))
          .catch(error => reject(error));
      }),
      meta: torPost
    });
  };
}
