import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Comments from "./Comments.js";
import ReportPost from "../common/ReportPost.js";
import Picture from "./Picture.js";

import { getLinkedPost } from "./previewActions.js";

import "bootstrap/dist/css/bootstrap.min.css";

const Preview = ({ history }) => {
  const torPost = usePost();

  const reddit = useSelector(state => state.globals.reddit);
  const post = useSelector(state => state.preview.post);

  const dispatch = useDispatch();
  useEffect(() => {
    if (torPost != null) {
      dispatch(getLinkedPost(torPost));
    }
  }, [dispatch, reddit, torPost]);

  const unclaimed = !torPost || torPost.link_flair_text === "Unclaimed";

  const nsfwTag = torPost && torPost.over_18 ? "[NSFW] " : "";
  const locked = torPost && torPost.locked;

  let title;
  if (!unclaimed) {
    title = (
      <h3 style={{ backgroundColor: "red" }}>
        This post has already been transcribed!
      </h3>
    );
  } else {
    if (post != null) {
      title = (
        <h2>
          {nsfwTag}
          {post.title}
        </h2>
      );
    } else {
      title = <h2>loading...</h2>;
    }
  }

  return (
    <>
      <div id="post-content">
        {title}
        <Picture post={post}></Picture>
        <div id="post-buttons">
          {locked && (
            <>
              <h3 style={{ backgroundColor: "red" }}>
                This post is locked and cannot be transcribed. Please go back.
              </h3>
              <br />
            </>
          )}
          <button
            id="claiming-button"
            onClick={() => claimTranscription(reddit, torPost)}
            disabled={!post || locked}
          >
            Click to claim
          </button>
          <ReportPost
            post={post}
            torPost={torPost}
            history={history}
          ></ReportPost>
          <button id="to-reddit" onClick={() => toReddit(post)}>
            To Reddit
          </button>
          <Link className="link-button" to="/random" id="random-post">
            <button type="button">Random</button>
          </Link>
          <Link className="link-button" to="/logged-in" id="to-queue">
            <button>To Queue</button>
          </Link>
        </div>
      </div>
      <div id="comments-section">
        <Comments post={post}></Comments>
      </div>
    </>
  );
};

export function usePost(initial) {
  // const dispatch = useDispatch();
  const reddit = useSelector(state => state.globals.reddit);

  // useMemo for this async function.
  const [post, setPost] = useState(initial);
  useEffect(() => {
    if (post != null) {
      return;
    }

    const postID = new URLSearchParams(window.location.search).get("id");
    getPost(reddit, postID).then(post => setPost(post));
  }, [post, reddit]);

  return post;
}

async function getPost(reddit, postID) {
  let post;
  try {
    post = await reddit.getSubmission(postID).fetch();
  } catch (_) {
    return;
  }

  return post;
}

function claimTranscription(reddit, torPost) {
  const unclaimed = torPost && torPost.link_flair_text === "Unclaimed";
  if (unclaimed === false) {
    const stillTranscribe = window.confirm(
      "Are you sure you want to accept this? This post has already been claimed and transcribed!"
    );
    if (!stillTranscribe) {
      return;
    }
  }

  const locked = torPost && torPost.locked;
  if (locked === true) {
    return;
  }

  console.log("Transcription claiming in progress for torPost: ", torPost);
  reddit
    .getSubmission(torPost)
    .reply(
      "claim -- this was a automated action. please contact me with any questions."
    )
    .then(() => {
      window.location.href = `/transcribe?id=${torPost.id}`;
    });
}

function toReddit(post) {
  window.open(`https://reddit.com/${post.id}`, "_blank");
}

export default Preview;
