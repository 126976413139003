/*

0 - raw format
1 - art no image
2 - images with text
3 - greentext
4 - reddit posts
5 - reddit comments
6 - facebook posts
7 - facebook comments
8 - sms
9 - twitter posts
10 - twitter replies
11 - comics
12 - gifs
13 - code
14 - memes
15 - other sources
16 - footer
17 - BBQ Tools
18 - Blue Button Rush
19 - Complex Mathmatics
20 - Drakeposting
21 - Change my mind
22 - Distracted Boyfriend
23 - excuse me what the fuck
24 - expanding brain
25 - expanding brain extended
26 - im in danger
27 - hard to swallow pills
28 - gru flipboard
29 - patrick not my wallet
30 - pupil dilation
31 - reminisce
32 - roll safe
33 - steve harvey regret
34 - see nobody cares
35 - Teutal argument
36 - try not to cry
37 - wednesday frog
38 - why are you like this
39 - why would you do this
40 - closet rustling
41 - don't deserve to pass
42 - Friendship ended
43 - Gay sounds angry
44 - Gay sounds conflicted
45 - Gay sounds distressed
46 - Gay sounds sad
47 - Gay sounds happy
48 - Gay sounds nervous
49 - Gay sounds very happy
50 - Gay sounds very nervous
51 - Gay sounds generic
52 - Guess I'll die
53 - Mascots Enby
54 - Mascots Tomboy
55 - Maybe I'll just die
56 - Sprinkling hormones
57 - This is fine
58 - Yellow hair red eyes
*/

export default [
  `*Image Transcription:*

---



---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Description of Image.*]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Description of anything that you think may be worth describing about the image itself aside from what the text says. For example, an unusual text font, background images or color, etc.*]

Text.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Greentext*

---

**Username**, MM/DD/YYYY, hh:mm

[*Description of anything that you think may be worth describing about the image itself aside from what the text says. For example, an unusual text font, background images or color, etc.*]

 Plaintext.

 > Greentext.

---

Next Post

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Reddit*

---

**Post title**, X points, submitted by **\\/u/Redditor** to **\\/r/Subreddit**, Gilded x#

[*Description of anything that you think may be worth describing about the image itself aside from what the text says. For example, an unusual text font, background images or color, etc.*]

Post text/content, if any.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Reddit*

---

> **\\/u/Commenting Redditor**, karma score

> Comment text.

>> **User Reports: Total Number of Reports**

>> Times reported: report reason A

>> Times reported: report reason B

>> etc.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Facebook Post*

---

**Name of Poster**

[*Description of Images, if any.*]

Text of post.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Facebook Comments*

---

**Name**:

[*Description of Images, if any.*]

Text of post.

> **Name of Replying Poster**

> [*Description of Images, if any.*]

> Text of post.

---


^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Text Messages*

---

[*Description of images, if any.*]

**Name of Poster1**: Text of post.

>**Name of Poster2**: Text of post.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Twitter Post*

---

**Name of Poster**, @Twitter handle

[*Description of Images, if any.*]

Text of post.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Twitter Post*

---

> **Name**, @Twitter handle

> [*Description of Images, if any.*]

> Text.

---


^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**Panel Number**

[*Description of the scene.*]

**Character**: Text.

---

Next panel

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: GIF*

---

[*Description of anything that you think may be worth describing about the image itself aside from what the text says. For example, an unusual text font, background images or color, etc*]

Text.

---

Repeat for each "scene" of the gif.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Code*

---

(four spaces) code

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Name of Meme (and description of any unusual features added, if any.)*]

#Top Text

#Bottom Text

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Source Name*

---

[*Description of images, if any.*]

**Name of Poster**: Text of post.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**Panel 1**

[*Two men are in the frame. The first man, who wears a purple top and black trousers, reaches dows into his bag. The second man, in a pink shirt and cyan trousers, holds up a skewer loaded with meat and vegetables.*]

**First Man**: I forgot the BBQ tools!

**Second Man**: Don't worry, I got it!

---

 **Panel 2**

[*A small laptop is visible in the frame. The second man's hand reaches towards the touch pad. On the laptop's screen is {__REPLACEME__}, a framework notorious for using an excessive amount of resources*]

---

**Panel 3**

[*The two men crouch down next to the laptop. They have fashioned Y shaped sticks to hold their skewer over the laptop, which is still {__REPLACEME__}. Smoke rises from the skewer and the men look proud*]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index) `,
  `*Image Transcription:*

---

[*Image of light skinned hand ("{{REPLACE ME}}")  blurred, indicating it is moving fast towards a blue button on a silvery metallic slab, with darker, silver, circular screws in the corners  ("{{REPLACE ME}}").*]



---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*A four panel comic of a light skinned feminine person with blond hair looking to the left of the camera and doing complex mathematics in their head. Algorithms are overlaid in each panel in white text.*]



---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Image is the Drake dabbing meme.*]

|Image|Text|
:--|:--
[*Drake (dark skinned masculine person with little hair that is brown. They have a beard and they are wearing an orange coat over a yellow shirt with a silhouette of a basketball player on it) looks displeased, and is using one arm to hide from the image by curling it around his head, with his hand up in a "not today" manner*]|{{REPLACE ME}}
[*Drake has their head up high, looking pleased, with a finger pointed at the camera*]|{{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*A man in a blue top is sat at a desk, outside. He is leaning back in his chair, with a hot beverage in his hand. The table has a sign attached reading:*]

#{{REPLACE ME}}

---

#CHANGE MY MIND


---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Distracted boyfriend meme: A masculine person with light skin and brown hair is walking away from the camera. A feminine person in a red dress walks towards the camera, and the masculine person is looking at her. Their presumed girlfriend (feminine person with light skin and brown, long hair, with a light blue shirt and blue jeans) looks disgusted at this behaviour. Each person has a label:*]

**Boyfriend**: {{REPLACE ME}}

**Girlfriend**: {{REPLACE ME}}

**Red Dress Person**: {{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Trancription: Excuse Me What the Fuck Meme*

---

{{REPLACE ME}}

[*Vault Boy, with dislocated shoulders and neck, looking down with a blank smile one his face (indicating he's confused), hands pointing to the front; is saying "excuse me what the fuck". Background is a vertical spectrum of green to dark blue.*]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Expanding Brain Meme*

---

[*Level 1: "Small" Brain*]

{{REPLACE ME}}

---

[*Level 2: "Normal" Brain, some glowing areas*]

{{REPLACE ME}}

---

[*Level 3: "Expanding" Brain, completely glowing*]

{{REPLACE ME}}

---

[*Level 4: "Maximum Expansion" Brain, completely glowing, shooting "glowing brainwaves" out of skull*]

{{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit!&#32;[If&#32;&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Expanding Brain Meme*

---

[*Level 1: "Small" Brain*]

{{REPLACE ME}}

---

[*Level 2: "Normal" Brain with Stars in background*]

{{REPLACE ME}}

---

[*Level 3: "Normal" Brain with Galaxy in background*]

{{REPLACE ME}}

---

[*Level 4: Upper Body with a glowing Red part and a lot of Stars in background*]

{{REPLACE ME}}

---

[*Level 5: Upper Body with most of it glowing red and holding their hand out in the direction of the viewer*]

{{REPLACE ME}}

---

[*Level 6: Person Meditating, completely visible*]

{{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit!&#32;[If&#32;&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of character from the Simpsons (Ralph) sitting on a red chair in a bus. There are no other people visible, but the character is in the last seat. There are clear windows showing the city, the floor is grey, and the bright orange-red chairs are held up by grey poles. Ralph is smiling and is unphased*]

**Ralph**: (chuckles)
I'm in danger

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Illustrations*

---

[*First image, a hand holding a big pill bottle. The label reads:*]

**hard to swallow pills**

[*Second, a hand with three pills in the palm.*]

**{{REPLACE ME}}**


---

^^I'm&#32;a&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Gru, the long-nosed protagonistic villain from Despicable Me, presents to the camera with passion, pointing into the air. Behind him is a flipchart. For all of these, the text is what the flipchart says.*]

## {{REPLACE ME}}

---
[*Gru is still presenting passionately; he has his hand in a c shape indicating a small amount*]

## {{REPLACE ME}}

---
[*Gru now has his hands pointing down, still presenting*]

## {{REPLACE ME}}

---
[*Gru looks back to the flipchart in a double take, looking confused and exasperated*]

## {{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Patrick 'Not My Wallet Meme':* ***Patrick*** *from Children's TV show* ***Spongebob Squarepants.****]

[*Patrick is a large starfish with green pants that have flowers, and no shirt. The comic is set in the ocean, and the background and floor are blue. Man Ray is a red masculine villain with a blue mask that covers their shoulders and they wear blue gloves and socks. The comic zooms in to the person who is talking.*]


**1**

[*Man Ray holding up an ID that reads PATricK StaR*]

Man Ray: {{REPLACE ME}}

---

**2**

[*Patrick is nodding, saying yes semi-mindlessly*]


Patrick: Yup

---

**3**

[*Man Ray holding the same ID, zoomed out.*]

Man Ray: {{REPLACE ME}}

---

**4**

[*Patrick is nodding, saying yes semi-mindlessly*]

Patrick:  Yup

---

**5**

Man Ray:  {{REPLACE ME}}

---
**6**

Patrick:  That makes sense to me.

---

**7**

Man Ray:  {{REPLACE ME}}

---

**8**

Patrick:  {{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit!&#32;[If&#32;&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---


Text | Image
----|-----
The pupil of your eye can expand as much as 55% while looking at something you love | Pupil  of a green coloured eye, with light skin around it, somewhat dilated
{{REPLACE ME}} | The same pupil, but it is tiny.


---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**Panel 1**

[*A post-apocolyptic styled scene. Two men sit on the ground, their legs crossed. Behind them is a derelict shopfront, the glass of the store window shattered on the ground in front of it. A red drape or fabric of some kind hangs in the window. One of the supporting beams is cracked about a third of the way up. On the floor next to the men is rubbish and rubble; a document with a skull on it lays in the corner of the image. The man on the left is dressed in what looks like a yellow fallout hood and a grey gas mask, with a green filter. He wears rubbery grey trousers. The man to the right wears a camoflague top, and rubberized dungarees with (presumably) his name, John, printed on them. He looks down, writing on a piece of paper*]

**Yellow Hood**: I miss the internet

---

**Panel 2**

[*The same scene as before, but John has finished drawing on the paper. He holds it up to Yellow Hood, who looks over*]

---

**Panel 3**

[*{__REPLACEME__}*]

---

**Panel 4**

[*A similar scene to __Panel 2__. Yellow Hood has taken the paper from John, and holds it, turning to look to him. John turns and looks to Yellow Hood*]

**Yellow Hood**: Thank you

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Roll Safe Meme*

---

[*Image of a dark skinned masculine person with a blue leather jacket. They have black, slightly curly, short hair and a moustache. They have a gold watch and necklace. The background is a blue wall with some glass that reflects a yellow wooden building. The person is pointing to their temple near the forehead with their right hand and grinning with a knowing look on their face.*]

{{Text}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of Steve Harvey (a dark-skinned masculine person with black eyebrows and moustache, a white shirt, a red tie, and a purple suit) laughing, overlaid by an image of them also being concerned or annoyed at themselves. The background is black.*]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**1**

[*Two masculine characters from Jurrasic Park are on a table at a restaurant at the beach, one who has a floral print shirt and glasses (Dennis Nedry), and another with a red shirt, a hat, and sunglasses (Lew Dodgson).*]

**Dennis Nedry** (Shouting):  {{Text}}!


---

**2**

[*The camera is now looking at them from Lew Dodgson's shoulder.*]

**Dennis Nedry**: SEE, NOBODY CARES.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*Image is a 5 panel meme of* ***Paul Teutal Sr.*** *and* ***Paul Teutal Jr*** *from reality TV show* ***American Chopper***.]

[*Paul Teutul Jr, who is clean shaven and wearing a cap, shouts at Paul Teutul Sr, an ageing man with a grey moustache and grey hair, sits in his chair*]

---

**1**

[*Teutul Sr yells towards the right of the frame, pointing off screen*]

**Sr**: ***{replaceme}***

---

**2**

[*Teutul Jr slams his hand down on the desk, yelling angrily*]

**Jr**: ***{replaceme}***

---

**3**

[*Teutul Sr has turned in his chair to face Jr more directly, and leans forward, now also angrily yelling*]

**Sr**: ***{replaceme}***

---

**4**

[*A chair is flying through the air towards the camera; it is unclear who has thrown it. Teutul Jr jumps away, whilst Sr, who is in the background, puffs his cheeks and leans forward. It is unclear who has spoken, however following the format of the comic it is likely Teutul Jr*]

**Jr**: ***{replaceme}***

---


**5**

[*Teutul Sr stands in a doorway, pointing angrily off camera while yelling*]

**Sr**: ***{replaceme}**


---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**1**

[*light skinned masculine person with grey hair and a white shirt and grey pants in the foetal position with their head to the right..*]

**Caption**: **LIE** DOWN

---

**2**

[*Zoomed in to their face, which is crying*]

**Caption**: **TRY** NOT TO CRY

---


**3**

[*Same as first except facing left and a tissue under their face. There is also a metal pole in the bottom right.*]

**Caption**: **CRY** A LOT

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Meme*

---

[*The below text accompanies an image of a large orange frog, which appears to be smiling. The frog is most commonly known from the "It is Wednesday, my dudes" meme.*]

[[REPLACE ME]]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of a light-skinned masculine person with brown hair and no clothes, except a towel on their left shoulder looking into the mirror, pointing at themselves. They appear somewhat happy. The image appears to be in a bathroom, and there is a brick wall with a white covering at the top half.*]

why are you like this

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic, Meme from The Eric Andre Show*

---

**1**

[*Dark skinned masculine person (Eric Andre - "{{DarkPerson}}") with black hair in an afro. They have a grey suit over a white shirt. The background is a wall with red rectangles in the middle. {{DarkPerson}} is pointing a gun at a dark skinned person (Hannibal Buress - "{{DeadPerson}}") in a blue shirt and black pants, making them appear dead. There is a table visible in the background with a globe, and a pot plant next to it.*]

[Gunshots]

---

**2**

[*{{DarkPerson}} look to the camera.*]

**{{DarkPerson}}**: Why would {{BlamedGroup}} do this?

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of a dark red closet, with lighter two panel dors. The outside is a gradient - blue to red to white to red - mirroring the colours on the trans flag.*]

###*MUFFLED CLOSET RUSTLING*

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)
`,
  `*Image Transcription:*

---

[*A light skinned masculine person wearing a blue shirt and black pants, holding a white cup. Their face is blurred. Their legs are crossed, and they are in front of a fake wooden desk, in front of which is two pieces of paper with the text on them. On the desk, there is a Physics textbook, a computer, and various other things. The wall is white and there are black closed blinds. The floor is carpetted with black and has some stripes on it.*]

YOU DON'T DESERVE  
 TO PASS  

\\---

 CHANGE MY MIND



---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image with two images of food in the bottom left and right corners ({{REPLACE ME}}, respectively) with green crosses over them. In the centre of the image, there is a picture of a light-skinned masculine person with their head replaced with {{REPLACE ME}} shaking hands with a white robe with the head being replaced with {{REPLACE ME}}.  There are curtains in the background. The text is a gradient - red to green, except "Now" is purple, and edited text is __bold__, and it is {{TEXT STYLE}}.*]

Friendship ended with **{{REPLACE ME}}**   
Now   
**{{REPLACE ME}}**  
 is my  
 best friend

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** from Re:Zero's face with their mouth and eyes wide open, and their fingers reaching over their behind their head. There are trees in the background. They have light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears, yellow eyes, and appear feminine. They have white and blue ribbons in their hair, a blue collar with a ribbon.*] 

DISTRESSED GAY SOUNDS

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in her hair, a blue collar with a ribbon. Behind them are some concrete pillars and a fence. The image is tinted dark compared to the "nervous" variant.*]

####*NERVOUS GAY SOUNDS*

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** from Re:Zero's face with their mouth and eyes wide open, and their fingers reaching over their behind their head. They are tinted pink/red and blurred, with trees in the background. They have light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears, yellow eyes, and appear feminine. They have white and blue ribbons in their hair, a blue collar with a ribbon.*] 

DISTRESSED GAY SOUNDS

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in their hair, a blue collar with a ribbon. They are holding back tears, and they are frowning.*]

####***SAD GAY SOUNDS***

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in their hair, a blue collar with a ribbon. The background is a background with a white stencil that says "HAPPY GAY SOUNDS", repeatedly. The text below is below the image and is blue.*]

###*HAPPY GAY SOUNDS*


---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in her hair, a blue collar with a ribbon. Behind them are some concrete pillars and a fence.*]

####*NERVOUS GAY SOUNDS*

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in their hair, a blue collar with a ribbon. Ferris is smiling with their mouth half open and a relaxed look on their face.*] 

###VERY HAPPY GAY SOUNDS

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris is feminine, has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They have white and blue ribbons in their hair, and a blue collar with a ribbon. Behind them is a green bench and a brown with a window that has a yellow outside. Outside this, there is some green trees visible.*]

#### VERY NERVOUS GAY SOUNDS

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image is* ***Ferris*** *from Re:Zero. Ferris has light skin, brown hair cut into a bob with a V-shaped fringe, brown cat ears and yellow eyes. They appear feminine and have white and blue ribbons in their hair, and a blue collar with a ribbon. {{REPLACE ME}}*]


---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Traa-tan: Anime styled  feminine-androgynous person with red jacket, with hands facing outward, mimicking the stock image similar.*]

##{{REPLACE ME}}

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of a light-to-dark skinned feminine person with white hair and "hair-ears". They are wearing a white shirt, and the background of the image is a gradient (top right to bottom left: white, red/pink, blue).*]

####DISTRESSED ENBY UTTERANCES

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*An image of a person with green-teal hair and green eyes. They are wearing a pastel blue shirt and a bowtie with stripes (blue, pink, white, pink, blue). The person has light skin and a second pair of "ears" in their hair - brown ones. The background of the image is a gradient (top right to bottom left: white, red/pink, blue), and there is a large margin around the image, and the text on the bottom in the margin.*]

####SAD MANLY NOISES

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*Image of a bedroom with a computer and a bed with a person lying down face down. The image is tinted dark.*]

Maybe I'll just die.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**1**

[*Person with a halo on top of their head (God), and an orange robe, looking at a yellow blob that is in the shape of a human on a purple mat.*]

**God**: THIS {{GENDER}} IS *ALMOST* DONE.

---

**2**

[*God picks up a salt shaker with a green substance in it, labelled "{{WRONG_HORMONE}}"*]

**God**: JUST NEEDS A *PINCH* OF {{CORRECT_HORMONE}}

---

**3**

[*God sprinkling {{WRONG_HORMONE}} on {{GENDER}} - only a couple of grains.*]


---
**4**

[*The salt shaker lid pops off and th man is covered in estrogen.]

**God**: that ain't {{CORRECT_HORMONE}}

---

**5**

[*God looking down from three clouds, and the {{GENDER}} is below, not visible and is talking.*]

**{{GENDER}}**: WHAT IS WRONG WITH MY BODY ??

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription: Comic*

---

**1**

[*There is a cartoon stylised feminine person (Flora from Fire Emblem Fates) with blue hair and a maid headband (appears like a maid headband). They have grey eyes and a black dress that has white on the edges and a red oval on the chest area. There is a fire engulfing the room and smoke blanketing the ceiling. There is a grey, empty frame on the green wall. Behind them is a door but it has fire behind it as well.*]

---

**2**

[*Same as the last panel but zoomed in on their face.*]

**Flora:** This is fine.

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  `*Image Transcription:*

---

[*A light skinned, anime stylised, feminine person (Tohru from Miss Kobayashi's Dragon Maid.) with yellow hair and green hair. Their red eyes are closing from joy, and they are flushed. Their mouth is in a "3" shape, starting down, going up, and then back down.  They are wearing a black top with a red tiw and white collars and shoulders. The roof is blue with white lights.*]

---

^^I'm&#32;a&#32;human&#32;volunteer&#32;content&#32;transcriber&#32;for&#32;Reddit&#32;and&#32;you&#32;could&#32;be&#32;too!&#32;[If&#32;you'd&#32;like&#32;more&#32;information&#32;on&#32;what&#32;we&#32;do&#32;and&#32;why&#32;we&#32;do&#32;it,&#32;click&#32;here!](https://www.reddit.com/r/TranscribersOfReddit/wiki/index)`,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``,
  ``
];
