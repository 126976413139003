import * as actions from "./settingsActions.js";

let theme = localStorage.getItem("theme");
if (theme !== "dark" && theme !== "light") {
  theme = "light";
  localStorage.setItem("theme", theme);
}

let nsfw = localStorage.getItem("nsfw_enabled");
if (nsfw !== "true" && nsfw !== "false") {
  nsfw = false;
  localStorage.setItem("nsfw", nsfw);
} else {
  nsfw = nsfw === "true";
}

let delete_enabled = localStorage.getItem("delete_enabled");
if (delete_enabled !== "true" && delete_enabled !== "false") {
  delete_enabled = false;
  localStorage.setItem("delete_enabled", delete_enabled);
} else {
  delete_enabled = delete_enabled === "true";
}

const waitTime = parseInt(localStorage.getItem("wait_time"), 10);
if (waitTime < 0 || Number.isNaN(waitTime)) {
  localStorage.setItem("wait_time", 0);
}

const defaultState = {
  nsfw: nsfw,
  theme: theme,
  delete_enabled: delete_enabled,
  waitTime: waitTime
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_NSFW: {
      return { ...state, nsfw: action.payload };
    }

    case actions.SET_THEME: {
      return { ...state, theme: action.payload };
    }

    case actions.SET_DELETE: {
      return { ...state, delete: action.payload };
    }

    case actions.SET_WAIT: {
      return { ...state, waitTime: action.payload };
    }

    default:
      return state;
  }
};
