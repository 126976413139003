//import axios from "axios";
//import { backendUrl } from "../common/common.js";
import snoowrap from "snoowrap";

export const LOAD_QUEUE = "LOAD_QUEUE";
export const LOAD_QUEUE_PENDING = `${LOAD_QUEUE}_PENDING`;
export const LOAD_QUEUE_FULFILLED = `${LOAD_QUEUE}_FULFILLED`;
export const LOAD_QUEUE_REJECTED = `${LOAD_QUEUE}_REJECTED`;

export const GET_POSTS_DATA = "GET_POSTS_DATA";
export const GET_POSTS_DATA_PENDING = "GET_POSTS_DATA";
export const GET_POSTS_DATA_FULFILLED = `${GET_POSTS_DATA}_FULFILLED`;
export const GET_POSTS_DATA_REJECTED = `${GET_POSTS_DATA}_REJECTED`;

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_PENDING = "GET_USER_DATA";
export const GET_USER_DATA_FULFILLED = `${GET_USER_DATA}_FULFILLED`;
export const GET_USER_DATA_REJECTED = `${GET_USER_DATA}_REJECTED`;

export const GO_TO_PAGE = "GO_TO_PAGE";
export const SET_RANK = "SET_RANK";
export const REMOVE_POST = "REMOVE_POST";

const urlRegex = /comments\/(.{6})/;

export function goToPage(page) {
  if (page == null) {
    page = localStorage.getItem("page");

    const pageNumber = parseInt(page, 10);
    if (isNaN(pageNumber)) {
      page = 1;
      localStorage.setItem("page", page);
    } else {
      page = parseInt(page, 10);
    }
  }

  return dispatch => {
    dispatch({
      type: GO_TO_PAGE,
      payload: page
    });
  };
}

export function getUserData(username) {
  return async function(dispatch, getState) {
    username = username || localStorage.getItem("reddit_username");
    const state = getState();

    const payload = userDataPayload(username, state);
    dispatch({
      type: GET_USER_DATA,
      payload: payload,
      meta: username
    });
  };
}

async function userDataPayload(username, state) {
  const getGamma = () => 
    new Promise((resolve, reject) => {
      /*axios
        .get(`${backendUrl}/gamma/${username}`, false)
        .then(data => {
          if (data.error !== "" && data.error != null) {
            reject(data.error);
          }

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });*/
        resolve({data:{"gamma":1}});
    });

  if (username == null || username === "") {
    const reddit = state.globals.reddit;
    const getUser = reddit.getMe();

    return getUser.then(user => {
      localStorage.setItem("reddit_username", user.name);
      return getGamma();
    });
  }

  return getGamma();
}

export function getPostsData() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_POSTS_DATA,
      payload: () =>
        new Promise((resolve, reject) => {
            const nsfw = getState().settings.nsfw;
            const refreshToken = localStorage.getItem("refresh_token");
            const clientID =
              process.env.NODE_ENV === "production"
                ? "DTam2q-mIcdJNQ"
                : "7ozm1DAE913NgQ";
            //const clientID = "7ozm1DAE913NgQ";
            const reddit = new snoowrap({
              userAgent: "not-tor-app-web v0.1 (by /u/ILikePokemonGo101)",
              clientId: clientID,
              clientSecret: "",
              refreshToken: refreshToken
            });

            reddit.config({ debug: true });
            
            getNewPosts(reddit,resolve,nsfw);
        })
    });
  };
}

function finalizePosts(resolve,nsfw){  
  var payload = {};
  payload.data = posts;
  payload.data = filterData(payload.data, nsfw);
  resolve(payload);
}

function filterData(posts, nsfwEnabled) {
  let hiddenPosts;
  let validData = true;
  try {
    hiddenPosts = JSON.parse(localStorage.getItem("hidden_posts"));
    validData = Array.isArray(hiddenPosts);
  } catch (_) {
    validData = false;
  }

  if (validData === false) {
    hiddenPosts = [];
    localStorage.setItem("hidden_posts", hiddenPosts);
  }

  return posts.filter(post => {
    // Filter posts that are NSFW when NSFW is not enabled and
    // Filter out posts that are hidden.
    return !(
      (!nsfwEnabled && post.over_18 === true) ||
      hiddenPosts.includes(post.id)
    );
  });
}

export function loadQueue() {
  return dispatch => {
    dispatch({
      type: LOAD_QUEUE,
      payload: Promise.all([
        dispatch(goToPage()),
        dispatch(getUserData()),
        dispatch(getPostsData())
      ])
    });
  };
}

export function setNextRank(rank) {
  return {
    type: SET_RANK,
    payload: rank
  };
}

export function nextPage() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: GO_TO_PAGE,
      payload: state.queue.page + 1
    });
  };
}

export function previousPage() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: GO_TO_PAGE,
      payload: state.queue.page - 1
    });
  };
}

export function removePost(post) {
  return dispatch => {
    dispatch({
      type: REMOVE_POST,
      payload: post
    });
  };
}

let posts;

function getNewPosts(reddit,resolve,nsfw) {
  reddit.getNew("TranscribersOfReddit", {limit:200}).then(myListing => {
      console.log("hi! m_l: ")
      console.log(myListing.length);
      var temporaryPosts = myListing.filter(function (el){
        return el.link_flair_text === "Unclaimed";
      });

      console.log(temporaryPosts.length);

      //console.log(posts);

      var array1 = temporaryPosts;
      var array2 = array1.splice(0, 100);
      addMoreInformation(array1, array2, reddit,resolve,nsfw);

      
  });
}

function addMoreInformation(array1, array2, reddit,resolve,nsfw) {
  let arrayTemp1 = [];
  let arrayTemp2 = [];

  for (let i = 0; i < array1.length; i++) {
      arrayTemp1.push("t3_" + urlRegex.exec(array1[i].url)[1]);
  }

  for (let i = 0; i < array2.length; i++) {
      arrayTemp2.push("t3_" + urlRegex.exec(array2[i].url)[1]);
  }

  console.log("a_t1-2: ");
  console.log(arrayTemp1.length);
  console.log(arrayTemp2.length);

  reddit.getContentByIds(arrayTemp1).then(
      listing1 => (
          reddit.getContentByIds(arrayTemp2)
              .then(listing2 => {
                  aggregateSubmissions(listing1, listing2, array1, array2,resolve,nsfw);
              })
      )
  );
}

function aggregateSubmissions(listing1, listing2, array1, array2,resolve,nsfw) {
  let temporaryPosts1 = [...array2, ...array1];
  const biglisting = [...listing2, ...listing1];

  for (let i = 0; i < biglisting.length; i++) {
      biglisting[i] = {
          ...biglisting[i],
          tor_id: temporaryPosts1[i].id,
          tor_permalink: temporaryPosts1[i].permalink,
          tor_title: temporaryPosts1[i].title,
          tor_score: temporaryPosts1[i].score
      }
  }
  console.log(temporaryPosts1);
  console.log(biglisting)
  const processedPosts = processPosts(biglisting);
  console.log("t_s: ");
  console.log(temporaryPosts1[0].tor_score);
  console.log(temporaryPosts1[listing2.length - 1].tor_score);

  posts = processedPosts;
  finalizePosts(resolve,nsfw);
}

function processPosts(allPosts) {
  const posts = [];

  for (let i = 0; i < allPosts.length; i++) {
      const post = allPosts[i];
      // The reddit instance information should not be in the endpoint.
      delete post._r;

      posts.push(post);
  }

  return posts;
}
