import React, { PureComponent } from "react";

const fileExtension = /\.(?:png|jpg|jpeg|gif)$/i;

// TODO: Maybe make it a hook, somehow.
class ExpandableImage extends PureComponent {
  constructor(props) {
    super(props);

    const post = props.post;

    // Preloads
    const fullImage = new Image();
    const state = this;
    this.fullImageListener = function() {
      state.setState({ fullDimensions: [this.width, this.height] });
    };
    fullImage.addEventListener("load", this.fullImageListener);

    fullImage.src = post.url;
    this.fullImage = fullImage;

    this.state = {
      post: post,
      thumbnailDimensions: null,
      fullDimensions: null,
      src: post.thumbnail,
      styles: { position: "absolute" },
      deleted: false
    };

    this.dataSrc = dataSrc(post);
    this.initialWidth = null;
    this.expandDownwards = null;
    this.expanding = false;
  }

  render() {
    const { post, styles, src, thumbnailDimensions } = this.state;
    const containerStyles = {
      position: "relative",
      width: thumbnailDimensions && thumbnailDimensions[0],
      height: thumbnailDimensions && thumbnailDimensions[1]
    };

    const containerClass =
      "image-container" + (thumbnailDimensions ? "" : " loading");

    return (
      <>
        {/* Keeps the same space when hovered. */}
        <div className={containerClass} style={containerStyles}>
          <img
            id={`img-${post.id}`}
            alt=""
            style={styles}
            className="img-thumbnail lazyload thumbnail-slow"
            data-sizes="auto"
            src={src}
            data-src={this.dataSrc}
            onMouseOver={this.expandImage}
            onFocus={this.expandImage}
            onMouseOut={this.collapseImage}
            onBlur={this.collapseImage}
            onLoad={this.getDimensions}
            tabIndex="0"
          />
        </div>
      </>
    );
  }

  expandImage = e => {
    // Just so that less work is done.
    if (this.expanding === true) {
      return;
    }

    // Should always run(might load in the middle of it).
    this.checkDirection(e);

    const { post, thumbnailDimensions } = this.state;
    const fullURL = post.url;

    const baseStyles = { position: "absolute", zIndex: 99 };

    const dimensionStyles = {
      width: thumbnailDimensions && thumbnailDimensions[0],
      height: thumbnailDimensions && thumbnailDimensions[1]
    };
    let styles;
    if (!fileExtension.test(fullURL)) {
      styles = { ...baseStyles, maxWidth: "150%" };

      // Starts from old width to prevent jumping.
      this.setState({ styles: dimensionStyles, src: post.thumbnail });
      this.setState({ styles: styles });
    } else {
      styles = { ...baseStyles, maxWidth: "350%" };
      this.setState({ styles: dimensionStyles, src: fullURL });
      this.setState({ styles: styles });

      if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
      }
    }

    this.expanding = true;
  };

  collapseImage = () => {
    if (this.expanding === false) {
      return;
    }

    const { post } = this.state;

    const baseStyles = { position: "absolute", maxWidth: "100%" };

    const styles = { ...baseStyles, zIndex: 98 };
    this.setState({
      styles: styles,
      src: fileExtension.test(post.url) ? post.url : post.thumbnail
    });

    this.timeout = setTimeout(() => {
      let src;
      if (isThumbnail(post.thumbnail)) {
        src = post.thumbnail;
      } else {
        src = post.url;
      }

      this.setState({ styles: baseStyles, src: src });
    }, 700);

    this.expanding = false;
  };

  getDimensions = e => {
    if (this.loaded === true) {
      return;
    }

    const target = e.target;
    const post = this.state.post;

    const rect = target.getBoundingClientRect();
    const dimensions = [rect.width, rect.height];

    if (e.target.src === dataSrc(post)) {
      this.setState({ thumbnailDimensions: dimensions });
    }

    this.rect = target.getBoundingClientRect();
    this.offset = target.offsetWidth - target.width;

    this.checkDirection(e);
    this.loaded = true;
  };

  checkDirection = e => {
    const target = e.target;
    const fullDimensions = this.state.fullDimensions;
    if (fullDimensions != null) {
      const scaledHeight =
        this.rect.width * 3.5 * (fullDimensions[1] / fullDimensions[0]) -
        this.offset;

      const rect = target.getBoundingClientRect();
      const y = rect.top + window.scrollY;

      if (
        y - scaledHeight > 0 &&
        y + scaledHeight > document.documentElement.scrollHeight
      ) {
        this.expandDownwards = false;
        target.classList.add("expand-upwards");
      } else {
        this.expandDownwards = true;
      }
    }
  };

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
    this.fullImage.removeEventListener("load", this.fullImageListener);
  }
}

export function showImage(post) {
  return dataSrc(post) != null;
}

function isThumbnail(thumbnail) {
  return (
    thumbnail !== "image" &&
    thumbnail !== "nsfw" &&
    thumbnail !== "spoiler" &&
    thumbnail !== "default"
  );
}

function dataSrc(post) {
  if (isThumbnail(post.thumbnail)) {
    return post.thumbnail;
  }

  if (fileExtension.test(post.url)) {
    return post.url;
  }
}

export default ExpandableImage;
