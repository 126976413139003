import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setNextRank } from "./queueActions.js";
import motivationalMessages from "../common/motivation.js";

const Progress = ({ user }) => {
  const dispatch = useDispatch();
  const nextRank = useMemo(() => getRankup(user), [user]);
  useEffect(() => {
    dispatch(setNextRank(nextRank));
  }, [dispatch, nextRank]);

  const transcriptionCount = user.gamma;

  const randomMessage = useMemo(getRandomMessage, []);

  const progressWidth = (transcriptionCount / nextRank.gamma) * 100;
  const progressClass = nextRank.css;
  const progressText = `${nextRank.gamma - transcriptionCount} away from ${
    nextRank.rankName
  }! ${randomMessage}`;

  return (
    <div className={`progress progress_bg_color`}>
      <div
        id="main_progressbar"
        className={`progress-bar ${progressClass}`}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: progressWidth + "%" }}
      >
        {progressText}
      </div>
    </div>
  );
};

const getRandomMessage = () => {
  return motivationalMessages[
    Math.floor(Math.random() * motivationalMessages.length)
  ];
};

const getRankup = user => {
  let nextRankNumber, nextRankCSS, nextRank;
  const transcriptionCount = user.gamma;
  if (transcriptionCount >= 5000) {
    nextRankNumber = 10000;
    nextRankCSS = "flair-grafeas-ruby";
    nextRank = "Ruby++";
  } else if (transcriptionCount >= 2500) {
    nextRankNumber = 5000;
    nextRankCSS = "flair-grafeas-ruby";
    nextRank = "Ruby+";
  } else if (transcriptionCount >= 1000) {
    nextRankNumber = 2500;
    nextRankCSS = "flair-grafeas-diamond";
    nextRank = "Ruby";
  } else if (transcriptionCount >= 500) {
    nextRankNumber = 1000;
    nextRankCSS = "flair-grafeas-golden";
    nextRank = "Diamond";
  } else if (transcriptionCount >= 250) {
    nextRankNumber = 500;
    nextRankCSS = "flair-grafeas-purple";
    nextRank = "Gold";
  } else if (transcriptionCount >= 100) {
    nextRankNumber = 250;
    nextRankCSS = "flair-grafeas-teal";
    nextRank = "Purple";
  } else if (transcriptionCount >= 50) {
    nextRankNumber = 100;
    nextRankCSS = "flair-grafeas";
    nextRank = "Teal";
  } else {
    //less than 50, gray recruit
    nextRankNumber = 50;
    nextRankCSS = "flair-grafeas";
    nextRank = "Teal";
  }

  return {
    gamma: nextRankNumber,
    rankName: nextRank,
    css: nextRankCSS
  };
};

export default Progress;
