import React from "react";
import { useSelector } from "react-redux";

import ExpandableImage, { showImage } from "./ExpandableImage.js";
import Vote from "../common/Vote.js";

import "lazysizes";
import ReportPost from "../common/ReportPost.js";

const Post = ({ post, history, hide }) => {
  const reddit = useSelector(state => state.globals.reddit);

  const postClass =
    "post post-row border rounded m-1 p-1 " +
    (post.over_18 ? "border-danger" : "border-secondary") +
    (post.can_gild ? "" : " removed"); // This is seriously the way to see if something is deleted.

  const permalink = new URL(post.permalink, "https://www.reddit.com/");

  // Acts like a snoowrap Reddit post instance with lazy loading.
  const fakePost = lazyLoad(reddit, post);

  const onKeyDown = e => {
    if (e.key === "h") {
      hide();
    } else if (e.key === "c") {
      history.push(`/post?id=${post.tor_id}`);
    }
  };

  return (
    <div tabIndex="0" className={postClass} onKeyDown={onKeyDown}>
      <div className="post-vote">
        <Vote votable={fakePost}></Vote>
      </div>
      <div className="post-info">
        <a href={permalink}>on r/{post.subreddit.display_name}</a>
        <div className="post-title">
          <h5>
            {post.over_18 ? "[NSFW] " : ""}
            {post.tor_title}
          </h5>
        </div>
        <div className="post-footer">
          <span>Comments: {post.num_comments}</span>
          <span> | </span>
          <a href={`/post?id=${post.tor_id}`}>Preview</a>
          <span> | </span>
          {hide && (
            <button className="button-link" id="hide-post" onClick={hide}>
              Hide
            </button>
          )}
          {hide && <span> | </span>}
          <ReportPost
            post={fakePost}
            torPost={fakePost.tor}
            history={history}
            trigger={handleOpen => (
              <button
                className="button-link"
                id="report-post"
                onClick={handleOpen}
              >
                Report Post
              </button>
            )}
          ></ReportPost>
        </div>
      </div>
      <div className="post-image">
        {showImage(post) && <ExpandableImage post={post}></ExpandableImage>}
      </div>
    </div>
  );
};

// Lazy loads the real post.
function lazyLoad(reddit, post) {
  const fakePost = {
    ...post,
    _r: reddit,
    tor: {
      id: post.tor_id
    }
  };

  getPost(reddit, fakePost);
  getPost(reddit, fakePost.tor);

  wrapMethods(fakePost);
  wrapMethods(fakePost.tor);

  return fakePost;
}

// Always returns a promise.
const getPost = (reddit, obj) => {
  obj.getPost = () =>
    new Promise((resolve, reject) => {
      if (obj.redditPost == null) {
        if (obj.promise == null) {
          // Setup reddit post promise.
          obj.promise = reddit
            .getSubmission(obj.id)
            .fetch()
            .then(post => {
              obj.redditPost = post;
              resolve(post);
            })
            .catch(error => {
              reject(error);
            })
            .finally(() => {
              obj.promise = null;
            });
        } else {
          // Queue behind the fetching.
          obj.promise.then(() => resolve(obj.redditPost)).catch(reject);
        }
      } else {
        // Resolve the post immediately.
        return resolve(obj.redditPost);
      }
    });
};

function wrapMethods(fakePost) {
  const wrapMethod = method => () =>
    fakePost.getPost().then(realPost => realPost[method]());
  fakePost.upvote = wrapMethod("upvote");
  fakePost.unvote = wrapMethod("unvote");
  fakePost.downvote = wrapMethod("downvote");
  fakePost.report = wrapMethod("report");
}

export default Post;
