import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import snoowrap from "snoowrap";

import { setAuthenticationURL } from "../actions.js";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const clientID =
      process.env.NODE_ENV === "production"
        ? "DTam2q-mIcdJNQ"
        : "7ozm1DAE913NgQ";

    const alphaNumeric = "abcdefghijklmnopqrstuvwxyz123456789";
    const stateLength = 26;
    let randomState = "";

    for (let i = 0; i < stateLength; i++) {
      const randomCharacterIndex = randomNumber(0, alphaNumeric.length);
      const randomCharacter = alphaNumeric.charAt(randomCharacterIndex);

      randomState += randomCharacter;
    }

    function randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    }
    localStorage.setItem("state", randomState);

    const authenticationUrl = snoowrap.getAuthUrl({
      clientId: clientID,
      clientSecret: "",
      scope: [
        "identity",
        "read",
        "submit",
        "report",
        "vote",
        "privatemessages",
        "edit"
      ],
      redirectUri: window.location.origin + "/reddit_callback",
      permanent: true,
      state: randomState
    });

    dispatch(setAuthenticationURL(authenticationUrl));
  }, [dispatch]);

  const authenticationURL = useSelector(
    state => state.globals.authenticationURL
  );
  return (
    <>
      <h2>Welcome to not-tor-app</h2>
      <h4>To continue, please hit authorize</h4>
      {/* This is necessary because it's to a different domain. */}
      <a id="authorize" href={authenticationURL}>
        <button>Click to Authorize with Reddit</button>
      </a>
    </>
  );
};

export default Home;
