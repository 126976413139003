import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { getPostsData } from "../queue/queueActions.js";

import "../../scss/main.scss";

const Random = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPostsData());
  }, [dispatch]);

  const posts = useSelector(state => state.queue.posts);
  if (!Array.isArray(posts) || posts.length === 0) {
    return <p>Finding the perfect random post for you!</p>;
  }

  const randomPost = posts[Math.floor(Math.random() * posts.length)];

  return <Redirect to={`/post?id=${randomPost.tor_id}`} push></Redirect>;
};

export default Random;
