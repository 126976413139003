import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { insertAtCursor } from "../common/common.js";
import templates from "../common/templates.js";
import * as Snudown from "snudown-js";

import { usePost } from "../preview/Preview.js";
import { makeEmbed, hidePost } from "../common/common.js";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const maxComment = 9500;
const minComment = 250; // Only in effect for splitting.
const platforms = ["apple", "facebook", "google", "twitter"];
const Transcribe = () => {
  const torPost = usePost();
  const post = useLinkedPost(torPost);
  const waitTime = useSelector(state => state.settings.waitTime);

  const [characters, setCharacters] = useState(0);
  const comments = Math.ceil(characters / maxComment);
  let i = 0;
  const formats = {
    Default: [
      "Raw Format",
      "Art and Images without Text",
      "Images with text",
      "Pictures with Greentext",
      "Reddit posts",
      "Reddit comments",
      "Facebook posts",
      "Facebook comments",
      "SMS/Text messages",
      "Twitter posts",
      "Twitter replies",
      "Comics",
      "GIFs",
      "Code",
      "Memes",
      "Other sources",
      "Generic Footer"
    ],
    "User Created": [
      "BBQ Tools",
      "Blue Button Rush",
      "Complex Mathmatics",
      "Drakeposting",
      "Change my Mind",
      "Distracted Boyfriend",
      "Excuse me what the fuck",
      "Expanding brain",
      "Expanding brain (extended)",
      "I'm in danger",
      "Hard to swallow pills",
      "Gru flipboard",
      "Patrick not my wallet",
      "Pupil dilation",
      "Reminisce",
      "Roll safe",
      "Steve Harvey regret",
      "See nobody cares",
      "Teutal Argument",
      "Try not to cry",
      "Wednesday Frog",
      "Why are you like this",
      "Why would you do this"
    ],
    Traa: [
      "Closet Rustling",
      "Don't deserve to pass",
      "Friendship ended",
      "Gay sounds angry",
      "Gay sounds conflicted",
      "Gay sounds distressed",
      "Gay sounds sad",
      "Gay sounds happy",
      "Gay sounds nervous",
      "Gay sounds very happy",
      "Gay sounds very nervous",
      "Gay sounds generic",
      "Guess I'll die",
      "Mascots Enby",
      "Mascots Tomboy",
      "Maybe I'll just die",
      "Sprinkling hormones",
      "This is fine",
      "Yellow hair red eyes"
    ]
  };

  const initialValue = useMemo(() => {
    if (post != null) {
      const initialValue = localStorage.getItem(`${post.id}-transcription`);

      return initialValue;
    }
  }, [post]);

  useMemo(() => {
    if (initialValue != null) {
      setCharacters(initialValue.length);

      const textbox = document.getElementById("transcription");
      if (textbox.value === "") {
        textbox.value = initialValue;
      }
    }
  }, [initialValue]);

  const [submit, setSubmit] = useState(false);
  const [emoji, setEmoji] = useState("apple");

  if (submit === true) {
    return <Redirect to="/logged-in"></Redirect>;
  }

  return (
    <>
      <div id="transcribe-content">
        <h5 className="postTitle">{post && post.title}</h5>
        {post && makeEmbed(post.url)}
      </div>
      <div id="transcribe-typing" className="transcription-typing">
        <div id="transcription-textbox">
          <textarea
            id="transcription"
            onKeyDown={e => ctrlEnter(e, torPost, post, waitTime, setSubmit)}
            onChange={e => onChange(e, post, setCharacters)}
            defaultValue={initialValue}
          ></textarea>
          <Picker
            id="emoji-mart"
            set={emoji}
            style={{
              position: "absolute",
              bottom: "380px",
              right: "20px",
              display: "none"
            }}
            title="Pick your emoji…"
            emoji="point_up"
            darkMode={localStorage.getItem("theme") === "dark"}
            onSelect={e =>
              insertAtCursor(document.getElementById("transcription"), e.native)
            }
          />
          <p id="character-count">
            {characters}/{maxComment} Characters, {comments} Comment
            {comments !== 1 ? "s" : ""}
          </p>
        </div>
        <div id="templates">
          <div id="buttons">
            {Object.entries(formats).map(formatGroup => (
              <div className="button_segment">
                <b>{formatGroup[0]} templates</b>
                <br></br>
                <select onChange={e => addText(e.target.value)}>
                  <option key={"None Selected"}>None Selected</option>
                  {formatGroup[1].map(format => (
                    <option value={i++} key={format}>
                      {format}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            <div className="button_segment2">
              <b>Emoji</b>
              <br></br>
              <select onChange={e => setEmoji(e.target.value)}>
                {platforms.map((name, i) => (
                  <option value={name} key={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div id="transcribe-buttons">
          <button
            id="submit-transcription"
            onClick={() =>
              postTranscription(post, torPost, waitTime).then(setSubmit(true))
            }
            disabled={post == null || torPost == null}
          >
            Submit Transcription
          </button>

          <button
            className="link-button"
            id="unclaim-transcription"
            onClick={() =>
              unclaimTranscription(post, torPost).then(setSubmit(true))
            }
            disabled={post == null || torPost == null}
          >
            Unclaim Transcription
          </button>

          <button
            id="to-reddit"
            className="display-other-buttons"
            type="button"
            onClick={() => toReddit(post)}
            disabled={post == null}
          >
            To Reddit
          </button>

          <button
            id="copy-ocr-bot"
            className="display-other-buttons"
            type="button"
            onClick={() => copyOCRBot(torPost)}
            disabled={post == null}
          >
            Paste OCR bot
          </button>

          <button
            id="display-emoji-button"
            className="display-other-buttons"
            type="button"
            onClick={enableEmojiMart}
          >
            Emoji button
          </button>

          <button
            id="display-markdown-button"
            type="button"
            onClick={displayInMarkdown}
          >
            Display in Markdown
          </button>
        </div>
      </div>
      <div id="markdown-viewer"></div>
    </>
  );
};

const urlRegex = /\/comments\/(.{6})/;
function useLinkedPost(torPost) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    if (torPost != null && post == null) {
      const id = urlRegex.exec(torPost.url)[1];
      torPost._r
        .getSubmission(id)
        .fetch()
        .then(post => setPost(post));
    }
  }, [torPost, post]);

  return post;
}

function ctrlEnter(e, torPost, post, waitTime, setSubmit) {
  if (e.ctrlKey && e.key === "Enter") {
    e.preventDefault();

    postTranscription(post, torPost, waitTime).then(() => setSubmit(true));
  }
}

const storeTranscription = debounce((textbox, post) => {
  if (post != null) {
    localStorage.setItem(`${post.id}-transcription`, textbox.value);
  }
}, 500);

function onChange(e, post, setCharacters) {
  setCharacterCount(e, setCharacters);
  storeTranscription(e.target, post);
}

function setCharacterCount(e, setCharacters) {
  const characters = e.target.value.length;
  setCharacters(characters);
}

const transcribotEnding2 = /---\n\nv0\.6\.0 \| This message was posted by a bot\. \| \[FAQ]\(https:\/\/www\.reddit\.com\/r\/TranscribersOfReddit\/wiki\/index\) \| \[Source]\(https:\/\/github\.com\/GrafeasGroup\/tor\) \| Questions\? \[Message the mods!]\(https:\/\/www\.reddit\.com\/message\/compose\?to=%2Fr%2FTranscribersOfReddit&subject=Bot%20Question&message=\)/gm;

async function postTranscription(post, torPost, waitTime) {
  const transcriptionText = document.getElementById("transcription").value;

  hidePost(post);
  const comments = splitTranscription(transcriptionText);

  for (const comment of comments) {
    console.log(comment);
    await post.reply(comment);
  }

  window.setTimeout(async () => {
    await torPost.reply(
      "done -- this was a automated action. please contact me with any questions."
    );
    localStorage.removeItem(`${post.id}-transcription`);
  }, waitTime * 1000);
}

async function unclaimTranscription(post, torPost) {
  hidePost(post);
  await torPost.reply(
    "unclaim -- this was a automated action. please contact me with any questions."
  );
}

function splitTranscription(body) {
  let isFirst = true;
  const comments = [];
  return (function _splitReply(body) {
    const continuation = isFirst
      ? ""
      : "Transcription: Continuation\n\n---\n\n";
    isFirst = false;

    const automaticallySplit =
      "\n\n[*this transcription has been automatically split into two parts due to its length. the continuation is in the replies.*]";
    if (body.length <= maxComment) {
      comments.push(continuation + body);
      return comments;
    } else {
      const [start, rest] = splitByWord(body);

      comments.push(continuation + start + automaticallySplit);
      return _splitReply(rest);
    }
  })(body);
}

function splitByWord(text) {
  let boundaryIndex = 0,
    nextBoundary = 0;

  const characters = Array.from(text);

  const splitWord = 50;
  const maxSize = Math.min(characters.length, maxComment + splitWord);
  for (let i = 0; i < maxSize; i++) {
    const character = characters[i];

    if (character === " " || character === "\t" || character === "\n") {
      if (i < maxComment) {
        boundaryIndex = i;
      } else {
        nextBoundary = i;
        break;
      }
    }
  }

  // If the next boundary occurs after splitWord characters, break the word.
  if (characters.length - nextBoundary - boundaryIndex > splitWord) {
    boundaryIndex = maxComment; // Will split a word.
  }

  boundaryIndex = Math.min(
    boundaryIndex,
    maxComment,
    characters.length - minComment
  );

  return [
    characters.slice(0, boundaryIndex).join(""),
    characters.slice(boundaryIndex).join("")
  ];
}

function displayInMarkdown() {
  const markdownViewer = document.getElementById("markdown-viewer");

  const markdowned = Snudown.markdown(
    splitTranscription(document.getElementById("transcription").value).join(
      "\n"
    )
  );
  //(for markdown js not needed for Snudown).replace(/&#32;/g," ").replace(/&amp;#32;/g," ").replace(/&#39;/g, "'")

  markdownViewer.innerHTML = markdowned;

  document.getElementById("markdown-viewer").style.display = "";
}

function copyOCRBot(torPost) {
  let i;
  for (i = 0; i < torPost.comments.length; i++) {
    if (torPost.comments[i].author.name === "transcribot") {
      break;
    }
  }

  if (i != null) {
    let totalBody = "";
    for (let z = 0; z < torPost.comments[i].replies.length; z++) {
      totalBody += torPost.comments[i].replies[z].body;
    }

    totalBody = totalBody.replace(transcribotEnding2, "");

    insertAtCursor(document.getElementById("transcription"), totalBody);
  } else {
    console.log("not there yet.");
  }
}

function addText(number) {
  const text = templates[number];
  insertAtCursor(document.getElementById("transcription"), text);
}

function debounce(callback, time) {
  let timeout;
  return function() {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...arguments), time);
  };
}

function enableEmojiMart() {
  if (
    document.getElementsByClassName("emoji-mart")[0].style.display === "none"
  ) {
    document.getElementsByClassName("emoji-mart")[0].style.display = "";
  } else {
    document.getElementsByClassName("emoji-mart")[0].style.display = "none";
  }
}

function toReddit(post) {
  window.open(`https://reddit.com/${post.id}`, "_blank");
}

export default Transcribe;
