import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import { loadApp, setCoC } from "./actions.js";

import Home from "./pages/SignIn.js";
import Random from "./pages/random/Random.js";
import Preview from "./pages/preview/Preview.js";
import CodeOfConduct from "./pages/CodeOfConduct.js";
import RedditCallback from "./pages/reddit_callback/RedditCallback.js";
import Queue from "./pages/queue/Queue.js";
import Settings from "./pages/settings/Settings.js";
import Transcribe from "./pages/transcribe/Transcribe.js";

import "./scss/dark.scss";
import "./scss/main.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowUp, faArrowDown);

const App = () => {
  const history = useHistory();
  useTheme();
  useCoC();
  return useRouting(history);
};

function useHistory() {
  const dispatch = useDispatch();
  const history = useMemo(() => createBrowserHistory(), []);

  useEffect(() => {
    dispatch(loadApp(history));
  }, [dispatch, history]);

  return history;
}

function useTheme() {
  const theme = useSelector(state => state.settings.theme);

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);
}

function useCoC() {
  const cocAgreed = localStorage.getItem("coc_agreed") === "true";
  const dispatch = useDispatch();

  dispatch(setCoC(cocAgreed));
}

function useRouting(history) {
  const refreshToken = useSelector(state => state.globals.refreshToken);
  const loading = useSelector(state => state.globals.loading);
  const cocAgreed = useSelector(state => state.globals.cocAgreed);

  if (loading === true) {
    return <></>;
  }

  const loggedIn = refreshToken !== "";

  return (
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <Switch>
        {!loggedIn && <Route exact path="/" component={Home}></Route>}
        {!loggedIn && (
          <Route path="/reddit_callback" component={RedditCallback}></Route>
        )}
        {!loggedIn && <Redirect to="/"></Redirect>}
        {!cocAgreed && (
          <Route path="/code_of_conduct" component={CodeOfConduct}></Route>
        )}
        {!cocAgreed && <Redirect to="/code_of_conduct"></Redirect>}
        <Route path="/logged-in" component={Queue}></Route>
        <Route path="/settings" component={Settings}></Route>
        <Route path="/post" component={Preview}></Route>
        <Route path="/random" component={Random}></Route>
        <Route path="/transcribe" component={Transcribe}></Route>
        <Redirect to="/logged-in"></Redirect>
      </Switch>
    </Router>
  );
}

export default App;
