import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { loadQueue } from "./queueActions.js";

import Posts from "./Posts.js";
import Progress from "./Progress.js";
import ChangePage from "./ChangePage.js";

import "bootstrap/dist/css/bootstrap.min.css";

export const postsPerPage = 40;

const Queue = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadQueue());

    const listener = e => dispatch(reload(e));
    window.addEventListener("keydown", listener);

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      localStorage.getItem("auto_change") !== "false"
    ) {
      localStorage.setItem("theme", "dark");
    } else if (localStorage.getItem("auto_change") !== "false") {
      localStorage.setItem("theme", "light");
    }

    return () => window.removeEventListener("keydown", listener);
  }, [dispatch]);

  // const loading = useSelector(state => state.queue.loading);
  const loadError = useSelector(state => state.queue.loadError);
  const displayError = useSelector(state => state.queue.displayError);
  const page = useSelector(state => state.queue.page);
  const pageCount = useSelector(state => state.queue.pageCount);
  const userLoaded = useSelector(state => state.queue.userLoaded);
  const user = useSelector(state => state.queue.user);

  return (
    <>
      <div
        id="progress-container"
        style={!userLoaded ? { visibility: "hidden" } : {}}
      >
        {!userLoaded && (
          <p id="progress-error">
            {!userLoaded && (displayError || loadError)}
          </p>
        )}
        <Progress user={user}></Progress>
      </div>
      <div className="container">
        <div>
          <h2 style={{ display: "inline" }}>Welcome to not-tor-app</h2>
          <a href="random" style={{ display: "inline", marginLeft: "362px" }}>
            random!
          </a>
        </div>
        <br />
        <ChangePage></ChangePage>
        <Posts history={history}></Posts>
        <ChangePage></ChangePage>
        <br />
        <span>
          Page:{" "}
          <span id="pageSpan">
            {page} of {pageCount}
          </span>
        </span>
        <br />
        <br />
        <a
          className="link-button"
          href="https://reddit.com/r/TranscribersOfReddit"
          id="to-ToR"
        >
          <button>To r/ToR</button>
        </a>
        <Link className="link-button" to="/settings" id="to-settings">
          <button>Settings</button>
        </Link>
      </div>
    </>
  );
};

const reload = e => {
  return dispatch => {
    if (e.key === "r") {
      dispatch(loadQueue());
      window.scrollTo(0, 0);
    }
  };
};

export default Queue;
