import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { setCoC } from "../actions.js";
// import { Agreed } from "./index.js";

const CodeOfConduct = () => {
  const dispatch = useDispatch();

  return (
    <>
      <h2>Code of Conduct</h2>
      <ul>
        <li>
          <p>
            <strong>Respect the Formatting:</strong> In an ideal world,
            transcriptions that you make should be indistinguishable from ones
            another redditor would make when working as a Transcriber of Reddit.{" "}
            <strong>This is really, really important.</strong> Continued and
            blatant disregard for proper formatting <em>will</em> be met with
            repercussions. Our content formatting is what first impressions are
            based on, and we need to always make a good first impression.
          </p>
        </li>
        <li>
          <p>
            <strong>Be Aware of Your Surroundings:</strong> When acting in
            official capacity (e.g. posting a transcription), if someone asks
            you a question, remember that you are temporarily the face of{" "}
            <a href="/r/TranscribersOfReddit">/r/TranscribersOfReddit</a>. Be
            courteous and friendly, and if you don&#39;t know how to answer,
            refer the user to{" "}
            <a href="https://www.reddit.com/r/TranscribersOfReddit/wiki/index">
              the ToR FAQ page
            </a>
            , an announcement post we&#39;ve made in their subreddit, or{" "}
            <a href="https://www.reddit.com/message/compose?to=%2Fr%2FTranscribersOfReddit">
              ask one of us
            </a>
            .{" "}
            <strong>
              It is always better to not answer than answer incorrectly.
            </strong>{" "}
            A useful way of responding might be: &quot;I&#39;m not sure about
            that but one of our moderators like{" "}
            <a href="https://www.reddit.com/u/captcoe">/u/captcoe</a> or{" "}
            <a href="https://www.reddit.com/u/itsthejoker">/u/itsthejoker</a>{" "}
            might be able to answer that for you.&quot;
          </p>
        </li>
        <li>
          <p>
            <strong>Use Formal Language:</strong> When talking to other users in
            your capacity as a Transcriber, please always use (relatively)
            formal language and proper grammar (capitalization, no
            abbreviations, and punctuation as appropriate).
          </p>
        </li>
        <li>
          <p>
            <strong>Ask Us if You Need Help:</strong> If you have any questions
            or find yourself in a situation that you cannot properly resolve,
            please send us a message{" "}
            <a href="https://www.reddit.com/message/compose?to=%2Fr%2FTranscribersOfReddit">
              at the modmail
            </a>{" "}
            or ping us through username so that we can assist.
          </p>
        </li>
      </ul>
      <h2>Penalties</h2>
      <p>
        Failure to follow the above rules is, at moderator&#39;s combined
        discretion, worthy of a temporary ban of 7 days. If issues continue, a
        permanent ban may be issued. (We really don&#39;t want to do this.) The
        thing to remember here is that we&#39;re here to do a service for those
        who need it, and we need to stay focused and on target. If there is some
        issue that prevents us from adequately doing that service, it&#39;s
        something we need to (and will) address.
      </p>
      <br />
      <br />
      <p>Please agree below to finish the registration process.</p>
      <Link id="agree" onClick={() => dispatch(setCoC(true))} to="/logged_in">
        I agree to the Code of Conduct.
      </Link>
    </>
  );
};

export default CodeOfConduct;
