import { applyMiddleware, createStore, combineReducers } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import globals from "./globals.js";
import settingsReducer from "./pages/settings/settingsReducer.js";
import loggedInReducer from "./pages/queue/queueReducer.js";
import previewReducer from "./pages/preview/previewReducer.js";
import transcribeReducer from "./pages/transcribe/transcribeReducer.js";

const reducers = combineReducers({
  globals: globals,
  settings: settingsReducer,
  queue: loggedInReducer,
  preview: previewReducer,
  transcribe: transcribeReducer
});
const middleware = applyMiddleware(promise, thunk, logger);
const store = createStore(reducers, middleware);

export default store;
