import React from "react";
import Vote from "../common/Vote.js";

import "../../scss/comments.scss";

const Comments = ({ post }) => {
  const locked = post && post.locked;
  const comments = post && post.comments;

  if (locked === true) {
    return (
      <div style={{ backgroundColor: "yellow" }}>
        This post has been locked!
      </div>
    );
  }

  if (comments == null) {
    return <p>Loading...</p>;
  }

  if (comments.length === 0) {
    return <h3>No comments!</h3>;
  }

  return comments.map(comment => (
    <div className="comment" key={comment.id}>
      {getComment(comment)}
    </div>
  ));
};

function getComment(comment) {
  const html = redditLinks(comment.body_html);

  const disginguishedStyle = comment.distinguished
    ? { backgroundColor: "green" }
    : {};
  const name = comment.author.name;
  const stickied = comment.stickied ? ", stickied" : "";
  const distinguished = comment.distinguished || "";

  const authorFlair = comment.author_flair_text;
  const flair = authorFlair !== "" && (
    <span className="flair">{authorFlair}</span>
  );

  const postTitle = (
    <p style={disginguishedStyle}>
      {`${distinguished} ${name}${stickied}`}
      {flair}
    </p>
  );

  return (
    <>
      <Vote votable={comment}>upvote</Vote>
      <div className="comment-text">
        {postTitle}
        <div
          className="html-body"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
    </>
  );
}

function redditLinks(html) {
  const container = document.createElement("div");
  container.innerHTML = html;

  const elements = container.querySelectorAll("[href],[src]");

  for (const element of elements) {
    // gets raw attributes.
    const href = element.getAttribute("href");
    const src = element.getAttribute("src");
    if (href != null) {
      const url = new URL(href, "https://www.reddit.com/"); // Makes relative paths absolute. Leaves others alone.
      element.href = url.href;
    }
    if (src != null) {
      const url = new URL(src, "https://www.reddit.com/");
      element.src = url.href;
    }
  }

  return container.innerHTML;
}

export default Comments;
