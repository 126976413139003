export default {
  rules: [
    {
      kind: "link",
      description:
        "Please use this report reason on bot posts that link to deleted content or a locked post. The bot will review them and verify the content is no longer there, then remove its own link.",
      short_name: "Original post has been deleted or locked",
      violation_reason: "Original post has been deleted or locked",
      created_utc: 1494163133,
      priority: 0,
      description_html:
        '<!-- SC_OFF --><div class="md"><p>Please use this report reason on bot posts that link to deleted content or a locked post. The bot will review them and verify the content is no longer there, then remove its own link.</p>\n</div><!-- SC_ON -->'
    },
    {
      kind: "link",
      description:
        "Please use this report reason on bot posts that link to content that should be marked as NSFW but for some reason are not.",
      short_name: "Post should be marked NSFW",
      violation_reason: "Post should be marked as NSFW",
      created_utc: 1504585320,
      priority: 1,
      description_html:
        '<!-- SC_OFF --><div class="md"><p>Please use this report reason on bot posts that link to content that should be marked as NSFW but for some reason are not.</p>\n</div><!-- SC_ON -->'
    },
    {
      kind: "all",
      description:
        "The only bots welcome in r/TranscribersOfReddit are ones we write or approve and u/BotBust. That's it. All other bot accounts are subject to banning on sight.",
      short_name: "No bot accounts but our own",
      violation_reason: "No bot accounts but our own",
      created_utc: 1523326933,
      priority: 2,
      description_html:
        '<!-- SC_OFF --><div class="md"><p>The only bots welcome in <a href="/r/TranscribersOfReddit">r/TranscribersOfReddit</a> are ones we write or approve and <a href="/u/BotBust">u/BotBust</a>. That&#39;s it. All other bot accounts are subject to banning on sight.</p>\n</div><!-- SC_ON -->'
    },
    {
      kind: "all",
      description:
        "Every subreddit that we partner with has their own rules for what can and can't be posted. For example, most of them have a \"no personal information\" rule about redacting screenshots. Please report these posts both on the subreddit we've linked to and ours.",
      short_name: "Post Violates Rules on Partner Subreddit",
      violation_reason: "Post Violates Rules on Partner Subreddit",
      created_utc: 1524086830,
      priority: 3,
      description_html:
        '<!-- SC_OFF --><div class="md"><p>Every subreddit that we partner with has their own rules for what can and can&#39;t be posted. For example, most of them have a &quot;no personal information&quot; rule about redacting screenshots. Please report these posts both on the subreddit we&#39;ve linked to and ours.</p>\n</div><!-- SC_ON -->'
    },
    {
      kind: "all",
      description: "",
      short_name: "Original Post has already a transcription",
      violation_reason: "Original Post has already a transcription",
      created_utc: 1561348535,
      priority: 4
    }
  ],
  site_rules: [
    "Spam",
    "Personal and confidential information",
    "Threatening, harassing, or inciting violence"
  ],
  site_rules_flow: [
    {
      reasonTextToShow: "This is spam",
      reasonText: "This is spam"
    },
    {
      nextStepHeader: "In what way?",
      reasonTextToShow: "This is abusive or harassing",
      nextStepReasons: [
        {
          nextStepHeader: "Who is the harassment targeted at?",
          reasonTextToShow: "It's targeted harassment",
          nextStepReasons: [
            {
              reasonTextToShow: "At me",
              reasonText: "It's targeted harassment at me"
            },
            {
              reasonTextToShow: "At someone else",
              reasonText: "It's targeted harassment at someone else"
            }
          ],
          reasonText: ""
        },
        {
          nextStepHeader: "Who is the threat directed at?",
          reasonTextToShow: "It threatens violence or physical harm",
          nextStepReasons: [
            {
              reasonTextToShow: "At me",
              reasonText: "It threatens violence or physical harm at me"
            },
            {
              reasonTextToShow: "At someone else",
              reasonText:
                "It threatens violence or physical harm at someone else"
            }
          ],
          reasonText: ""
        },
        {
          reasonTextToShow: "It's rude, vulgar or offensive",
          reasonText: "It's rude, vulgar or offensive"
        }
      ],
      reasonText: ""
    },
    {
      nextStepHeader: "What issue?",
      reasonTextToShow: "Other issues",
      nextStepReasons: [
        {
          complaintButtonText: "File a complaint",
          complaintUrl:
            "https://www.reddit.com/api/report_redirect?thing=%25%28thing%29s&reason_code=COPYRIGHT",
          complaintPageTitle: "File a complaint?",
          reasonText: "It infringes my copyright",
          reasonTextToShow: "It infringes my copyright",
          fileComplaint: true,
          complaintPrompt:
            "If you think content on Reddit violates your intellectual property, please file a complaint at the link below:"
        },
        {
          complaintButtonText: "File a complaint",
          complaintUrl:
            "https://www.reddit.com/api/report_redirect?thing=%25%28thing%29s&reason_code=TRADEMARK",
          complaintPageTitle: "File a complaint?",
          reasonText: "It infringes my trademark rights",
          reasonTextToShow: "It infringes my trademark rights",
          fileComplaint: true,
          complaintPrompt:
            "If you think content on Reddit violates your intellectual property, please file a complaint at the link below:"
        },
        {
          reasonTextToShow: "It's personal and confidential information",
          reasonText: "It's personal and confidential information"
        },
        {
          reasonTextToShow:
            "It's sexual or suggestive content involving minors",
          reasonText: "It's sexual or suggestive content involving minors"
        },
        {
          nextStepHeader: "Do you appear in the image?",
          reasonTextToShow: "It's involuntary pornography",
          nextStepReasons: [
            {
              reasonTextToShow: "I appear in the image",
              reasonText: "It's involuntary pornography and i appear in it"
            },
            {
              reasonTextToShow: "I do not appear in the image",
              reasonText:
                "It's involuntary pornography and i do not appear in it"
            }
          ],
          reasonText: ""
        },
        {
          reasonTextToShow:
            "It's a transaction for prohibited goods or services",
          reasonText: "It's a transaction for prohibited goods or services"
        },
        {
          complaintButtonText: "File a complaint",
          complaintUrl:
            "https://www.reddit.com/api/report_redirect?thing=%25%28thing%29s&reason_code=NETZDG",
          complaintPageTitle: "File a complaint?",
          reasonText: "Report this content under NetzDG",
          reasonTextToShow: "Report this content under NetzDG",
          fileComplaint: true,
          complaintPrompt:
            "This reporting procedure is only available for people in Germany. If you are in Germany and would like to report this content under the German Netzwerkdurchsetzungsgesetz (NetzDG) law you may file a complaint by clicking the link below."
        },
        {
          complaintButtonText: "Report Self-Harm",
          complaintUrl:
            "https://www.reddit.com/api/report_redirect?thing=%25%28thing%29s&reason_code=SELF_HARM",
          complaintPageTitle: "Report Threat of Suicide or Self-Harm",
          reasonText: "It's threatening self-harm or suicide",
          reasonTextToShow: "It's threatening self-harm or suicide",
          fileComplaint: true,
          complaintPrompt:
            "If you think the related content creator might be at risk of suicide or self-harm, please file a report. Use the link below:"
        }
      ],
      reasonText: ""
    }
  ]
};
