import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setRefreshToken } from "../../actions.js";

import snoowrap from "snoowrap";

import "../../scss/main.scss";

const RedditCallback = ({ history }) => {
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    onAuth2()
      .then(r => {
        dispatch(setRefreshToken(r.refreshToken));
        if (localStorage.getItem("coc_agreed") === "true") {
          history.replace("/logged_in");
        } else {
          history.replace("/code_of_conduct");
        }
      })
      .catch(err => setError(err.message));
  }, [history, dispatch]);

  if (error !== "") {
    return <h4>{error}</h4>;
  }

  return <h4>Checking</h4>;
};

function onAuth2() {
  const code = new URL(window.location.href).searchParams.get("code");
  const state = new URL(window.location.href).searchParams.get("state");

  const clientID =
    process.env.NODE_ENV === "production" ? "DTam2q-mIcdJNQ" : "7ozm1DAE913NgQ";
  if (state !== localStorage.getItem("state")) {
    alert("Please try again!");
    window.location = "/";
  } else {
    return snoowrap
      .fromAuthCode({
        code: code,
        clientId: clientID,
        clientSecret: "",
        userAgent: "not-tor-app-web v0.1 (by /u/ILikePokemonGo101)",
        redirectUri: window.location.origin + "/reddit_callback"
      })
      .then(r => r.getMe())
      .then(user => {
        localStorage.setItem("reddit_username", user.name);
        localStorage.removeItem("state");

        const r = user._r;
        if (r.scope.includes("vote")) {
          localStorage.setItem("voting", true);
          console.log("We have voting permissions!");
        } else {
          localStorage.setItem("voting", false);
        }

        return r;
      });
  }
}

export default RedditCallback;
