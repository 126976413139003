import * as actions from "./queueActions.js";
import { postsPerPage } from "./Queue.js";

const defaultState = {
  loading: true,
  loadError: "",
  displayError: "",
  postsLoaded: false,
  userLoaded: false,
  redirecting: false,
  redirectTo: "",
  refreshToken: "",
  theme: "light",
  posts: [],
  page: 1,
  pageCount: 1,
  user: {
    gamma: 0
  },
  nextRank: {
    gamma: 0,
    rankName: "",
    css: ""
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.LOAD_QUEUE_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actions.LOAD_QUEUE_FULFILLED: {
      return {
        ...state,
        loading: false
      };
    }

    case actions.LOAD_QUEUE_REJECTED: {
      return {
        ...state,
        loading: false,
        loadError: action.loadError || state.loadError,
        displayError: action.displayError || state.displayError
      };
    }

    case actions.GET_POSTS_DATA_PENDING: {
      return { ...state, postsLoaded: false };
    }

    case actions.GET_POSTS_DATA_FULFILLED: {
      const posts = action.payload.data;
      const pageCount = Math.ceil(posts.length / postsPerPage);

      return {
        ...state,
        postsLoaded: true,
        posts: posts,
        pageCount: pageCount
      };
    }

    case actions.GET_POSTS_DATA_REJECTED: {
      return {
        ...state,
        postsLoaded: true,
        loadError: action.payload.statusText,
        displayError: "Could not load posts!"
      };
    }

    case actions.GET_USER_DATA_PENDING: {
      return {
        ...state,
        userLoaded: false
      };
    }

    case actions.GET_USER_DATA_FULFILLED: {
      return {
        ...state,
        userLoaded: true,
        user: action.payload.data
      };
    }

    case actions.GET_USER_DATA_REJECTED: {
      const error =
        action.error ||
        (action.data && action.data.error) ||
        action.payload.error;
      return {
        ...state,
        userLoaded: false,
        loadError: error,
        displayError: "Could not load user data."
      };
    }

    case actions.SET_RANK: {
      return {
        ...state,
        nextRank: { ...state.nextRank, ...action.payload }
      };
    }

    case actions.GO_TO_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }

    case actions.REMOVE_POST: {
      const posts = state.posts.filter(post => post !== action.payload);
      const pageCount = Math.ceil(posts.length / postsPerPage);
      const page = Math.min(state.page, pageCount);

      return {
        ...state,
        posts,
        pageCount,
        page
      };
    }

    default:
      return state;
  }
};
