import React from "react";

export const backendUrl = "https://tor.magma.lol";

const fileExtension = /\.(?:png|jpg|jpeg|gif)$/i;
const imgurRegex = /^https:\/\/(?:i\.)?imgur\.com\/((?:a\/)?[a-zA-Z0-9]+)(?:\.gifv)?$/;
const redditRegex = /^https:\/\/v\.redd\.it\/([a-z0-9]+)$/;
const youtubeRegex = /\/watch\?v=([a-zA-Z0-9!@#$%^&*(),.?":{}|<>-_]+)$/;

export function makeEmbed(url) {
  if (!fileExtension.test(url)) {
    if (youtubeRegex.test(url)) {
      const youtubeMatches = url.match(youtubeRegex);
      const src = `https://www.youtube.com/embed/${youtubeMatches[1]}`;

      return (
        <iframe
          title="Youtube Video"
          id="ytplayer"
          type="text/html"
          width="450"
          height="350"
          src={src}
        ></iframe>
      );
    } else if (redditRegex.test(url)) {
      return createVideo(url + "/DASH_240");
    } else if (imgurRegex.test(url)) {
      const imgurMatches = url.match(imgurRegex);
      if (imgurMatches[1].startsWith("a/")) {
        const id = imgurMatches[1];

        return (
          <>
            <blockquote className="imgur-embed-pub" lang="en" data-id={id}>
              <a href={url}>View post on imgur.com</a>
            </blockquote>
            <script src="//s.imgur.com/min/embed.js" charset="utf-8"></script>
          </>
        );
      } else if (imgurMatches[1].endsWith(".gifv")) {
        return createVideo(imgurMatches[1]);
      } else {
        const src = `https://i.imgur.com/${imgurMatches[1]}.png`;
        return <img src={src} width="450" height="auto" alt=""></img>;
      }
    } else {
      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.width = 450;
      iframe.style.height = "auto";

      return (
        <iframe
          title="Default Frame"
          src={url}
          width="450"
          height="auto"
        ></iframe>
      );
    }
  } else {
    return <img alt="" src={url} width="450" height="auto"></img>;
  }
}

export function createVideo(url) {
  return (
    <video width="450" height="auto" control>
      <source src={url} type="video/mp4"></source>
    </video>
  );
}

// :( fallback has to be used for firefox but chrome and firefox still work so I'm fine enough with this https://jsbin.com/baxusiw/edit?html,js,logger,output
export function insertAtCursor(textarea, newText) {
  if (typeof textarea === "string") {
    textarea = document.querySelector(textarea);
  }
  textarea.focus();

  let pasted = true;
  try {
    if (!document.execCommand("insertText", false, newText)) {
      pasted = false;
    }
  } catch (e) {
    console.error("error caught:", e);
    pasted = false;
  }
  // fallback
  if (!pasted) {
    console.error(
      "paste unsuccessful, execCommand not supported. thanks mozilla and bug 1220696. fallback being used."
    );

    // Credit: https://stackoverflow.com/questions/11076975/insert-text-into-textarea-at-cursor-position-javascript/11077016#11077016
    if (textarea.selectionStart || textarea.selectionStart === 0) {
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, startPos) +
        newText +
        textarea.value.substring(endPos, textarea.value.length);
      textarea.selectionStart = startPos + newText.length;
      textarea.selectionEnd = textarea.selectionStart;
    } else {
      console.error(
        "fallback for mozilla also unsuccessful (probably using IE) - pasting text at the end"
      );
      textarea.value += newText;
    }
  }
}

export function hidePost(post) {
  const id = post.id;
  let hiddenPosts;
  try {
    hiddenPosts = JSON.parse(localStorage.getItem("hidden_posts"));
  } catch (_) {}

  if (!Array.isArray(hiddenPosts)) {
    hiddenPosts = [];
  }

  hiddenPosts.unshift(id);
  hiddenPosts = hiddenPosts.slice(0, 101);

  localStorage.setItem("hidden_posts", JSON.stringify(hiddenPosts));
}
