import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Post from "./Post.js";

import { postsPerPage } from "./Queue.js";
import { removePost } from "./queueActions.js";
import { hidePost } from "../common/common.js";

const Posts = ({ history }) => {
  const loadError = useSelector(state => state.queue.loadError);
  const displayError = useSelector(state => state.queue.displayError);
  const allPosts = useSelector(state => state.queue.posts);
  const postsLoaded = useSelector(state => state.queue.postsLoaded);
  const page = useSelector(state => state.queue.page);

  const start = (page - 1) * postsPerPage;
  const end = page * postsPerPage;
  const posts = allPosts.slice(start, end);

  const dispatch = useDispatch();

  if (posts.length === 0) {
    if (postsLoaded) {
      return <p>Looks like there are no posts to display...</p>;
    } else {
      return <p>Loading posts...</p>;
    }
  }

  const hide = post => {
    const postElem = focusedPost();
    const nextElem = postElem && postElem.nextSibling;

    dispatch(removePost(post));
    hidePost(post);

    if (postElem != null && nextElem != null) {
      nextElem.focus();
    }
  };

  return (
    <div id="posts" onKeyDown={postOnKeydown}>
      {posts.length === 0 && (displayError || loadError)}
      {posts.map((post, i) => (
        <Post
          key={post.tor_id}
          post={post}
          history={history}
          hide={() => hide(post)}
        ></Post>
      ))}
    </div>
  );
};

function postOnKeydown(e) {
  // Posts have focus.
  if (hasFocus(e.target)) {
    const post = focusedPost();

    let elem;
    if (e.key === "ArrowUp") {
      elem = post.previousSibling;
    } else if (e.key === "ArrowDown") {
      elem = post.nextSibling;
    }

    if (elem != null) {
      elem.focus();
      e.preventDefault();
    }
  }
}

function hasFocus(parent) {
  const focusedElement = document.activeElement;
  return focusedElement === parent || hasChild(parent, document.activeElement);
}

function hasChild(parent, node) {
  node = node.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

function focusedPost() {
  let node = document.activeElement;
  while (node != null) {
    if (node.classList != null && node.classList.contains("post")) {
      return node;
    }

    node = node.parentNode;
  }

  return null;
}

export default Posts;
