import React, { useMemo, useState } from "react";
import MicroModal from "react-micro-modal";

import transcriptionRules from "./rules.js";
import { hidePost } from "./common.js";

const ReportPost = ({ post, torPost, history, trigger, onReport }) => {
  trigger =
    trigger ||
    (handleOpen => (
      <button id="report-post" onClick={handleOpen}>
        Report Post
      </button>
    ));

  const [subRules, setRules] = useState(null);
  const [subOptions, torOptions] = useMemo(() => getRuleOptions(subRules), [
    subRules
  ]);

  if (post == null) {
    return (
      <button id="report-post" disabled>
        Report Post
      </button>
    );
  }

  let subElems;
  let subSelected = "none";
  if (subOptions != null) {
    // These won't be re-rendered so a index key is okay.
    // If these need to change, find a good key first.
    subElems = subOptions.map((rule, i) => {
      if (rule.selected) {
        subSelected = rule.value;
      }

      return (
        <option key={i} value={rule.value}>
          {rule.text}
        </option>
      );
    });
  }

  let torElems;
  let torSelected = "none";
  if (torOptions != null) {
    torElems = torOptions.map((rule, i) => {
      if (rule.selected) {
        torSelected = rule.value;
      }

      return (
        <option key={i} value={rule.value}>
          {rule.text}
        </option>
      );
    });
  }

  const wrapHandle = handleOpen => {
    return () => {
      getRules(post._r, post.subreddit.display_name).then(rules =>
        setRules(rules)
      );
      handleOpen();
    };
  };

  const reportRules = handleClose => {
    reportForRules(post, torPost)
      .then(() => {
        if (typeof onReport === "function") {
          const value = onReport();

          if (value != null && value.hasOwnProperty("then")) {
            return value;
          } else {
            return Promise.resolve();
          }
        } else {
          return Promise.resolve();
        }
      })
      .then(handleClose)
      .then(() => history.push("/logged-in"));
  };

  return (
    <MicroModal trigger={handleOpen => trigger(wrapHandle(handleOpen))}>
      {handleClose => (
        <>
          <div id="rules-report">
            <header className="modal-header">
              <span>Report For Rules: </span>
              <span onClick={handleClose}>X</span>
            </header>

            <section>
              <p>Transcribers of Reddit Rules: </p>
              {!torOptions && <p>No Rules for ToR.</p>}
              {torOptions && (
                <select id="tor-report-options" defaultValue={torSelected}>
                  {torElems}
                </select>
              )}
              <br />
              <p>Subreddit Rules: </p>
              {subRules == null && <p>Loading Rules...</p>}
              {subRules != null && (
                <>
                  {!subOptions && <p>No Rules on sub!</p>}
                  {subOptions && (
                    <select id="report-options" defaultValue={subSelected}>
                      {subElems}
                    </select>
                  )}
                </>
              )}
            </section>
          </div>
          <br />
          <button
            id="report-for-rules"
            onClick={() => reportRules(handleClose)}
          >
            Click to report for a rules violation
          </button>
        </>
      )}
    </MicroModal>
  );
};

function getRules(reddit, subredditName) {
  return new Promise((resolve, reject) => {
    // Must be a promise not just thenable.
    reddit
      .getSubreddit(subredditName)
      .getRules()
      .then(payload => {
        if (!Array.isArray(payload.rules)) {
          console.warn("Rules payload was not an array!");
          reject("Rules not an array!");
        }
        resolve(payload);
      })
      .catch(reject);
  });
}

async function reportForRules(post, torPost) {
  const reportElem = document.getElementById("report-options");
  const torReportElem = document.getElementById("tor-report-options");

  const reportOption = (reportElem && reportElem.value) || "none";
  const torReportOption = (torReportElem && torReportElem.value) || "none";

  if (reportOption !== "none" || torReportOption !== "none") {
    console.log("Report Options: ", reportOption);
    console.log("ToR Report Options: ", torReportOption);
    hidePost(post);

    if (reportOption !== "none") {
      await post.report({ reason: reportOption });
    }

    if (torReportOption !== "none") {
      await torPost.report({ reason: torReportOption });
    }

    console.log("This post has been reported.");
  }
}

function getRuleOptions(subRules) {
  if (subRules == null) {
    return [null, null];
  }

  const subOptions = [{ value: "none", text: "N/A" }];
  const torOptions = [{ value: "none", text: "N/A" }];

  for (const rule of subRules["rules"]) {
    const violationReason = rule["violation_reason"];
    subOptions.push({ value: violationReason, text: violationReason });
  }

  for (const rule of transcriptionRules["rules"]) {
    const violationReason = rule["violation_reason"];
    let ruleSelection = { value: violationReason, text: violationReason };

    if (violationReason === "Post Violates Rules on Partner Subreddit") {
      ruleSelection.selected = true;
    }

    torOptions.push(ruleSelection);
  }

  return [subOptions, torOptions];
}

export default ReportPost;
