import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setRefreshToken } from "../../actions.js";

import { setNSFW, setTheme, setDelete, setWait } from "./settingsActions.js";

const Settings = ({ history }) => {
  const theme = useSelector(state => state.settings.theme);
  const nsfwEnabled = useSelector(state => state.settings.nsfw);
  const autoDeleteEnabled = useSelector(state => state.settings.delete);
  const waitTime = useSelector(state => state.settings.waitTime);

  const dispatch = useDispatch();
  const toggleNSFW = useCallback(() => {
    if (nsfwEnabled === true) {
      dispatch(setNSFW(false));
    } else {
      dispatch(setNSFW(true));
    }
  }, [dispatch, nsfwEnabled]);

  const toggleDelete = useCallback(() => {
    if (autoDeleteEnabled === true) {
      dispatch(setDelete(false));
    } else {
      dispatch(setDelete(true));
    }
  }, [dispatch, autoDeleteEnabled]);

  const changeTheme = useCallback(() => {
    if (theme === "dark") {
      dispatch(setTheme("light"));
    } else {
      dispatch(setTheme("dark"));
    }
  }, [dispatch, theme]);

  const changeWait = useCallback(
    e => {
      const value = parseInt(e.target.value, 10);

      if (!Number.isNaN(value)) {
        dispatch(setWait(value));
      }
    },
    [dispatch]
  );

  const logout = history => {
    localStorage.removeItem("refresh_token");
    dispatch(setRefreshToken(""));
    history.push("/");
  };

  const block = { display: "block" };
  return (
    <>
      <div>
        <button
          type="button"
          id="toggle-nsfw"
          onClick={toggleNSFW}
          style={block}
        >
          {(!nsfwEnabled ? "Enable" : "Disable") + " NSFW"}
        </button>
      </div>
      <button
        type="button"
        id="toggle-delete"
        onClick={toggleDelete}
        style={block}
      >
        {(!autoDeleteEnabled ? "Enable" : "Disable") + " Auto Deleting"}
      </button>
      <button
        type="button"
        id="change-theme"
        onClick={changeTheme}
        style={block}
      >
        Change Theme
      </button>
      <label htmlFor="change-wait">Comment done Wait (Seconds):</label>
      <input
        type="number"
        name="change-wait"
        onChange={changeWait}
        value={waitTime}
      />
      <Link to="/" id="logout" onClick={() => logout(history)} style={block}>
        <button>Logout</button>
      </Link>
      <Link to="/logged-in" id="back" style={block}>
        <button>Back to Queue</button>
      </Link>
    </>
  );
};

export default Settings;
