import React from "react";
import { makeEmbed } from "../common/common.js";

const Picture = ({ post }) => {
  if (post == null) {
    return <p>Loading...</p>;
  }

  const embed = makeEmbed(post.url);

  return embed;
};

export default Picture;
