import * as actions from "./actions.js";

const defaultState = {
  cocAgreed: false,
  loading: true,
  reddit: null,
  postsLoaded: false,
  postsError: "",
  redirecting: false,
  redirectTo: "",
  refreshToken: "",
  redirectURL: "",
  history: {
    length: 1,
    action: "",
    location: {
      pathname: "",
      search: "",
      hash: "",
      state: {}
    },
    push: () => {},
    replace: () => {},
    go: () => {},
    goBack: () => {},
    goForward: () => {},
    block: () => {}
  },
  subRules: {
    rules: []
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_REDDIT: {
      return { ...state, reddit: action.payload };
    }

    case actions.SET_REFRESH_TOKEN: {
      return { ...state, refreshToken: action.payload };
    }

    case actions.SET_AUTHENTICATION_URL: {
      return { ...state, authenticationURL: action.payload };
    }

    case actions.SET_LOADING: {
      return { ...state, loading: action.payload };
    }

    case actions.GET_RULES_FULFILLED: {
      return { ...state, subRules: action.payload };
    }

    case actions.SET_COC: {
      return { ...state, cocAgreed: action.payload };
    }

    default:
      return state;
  }
};
