export default [
  "Keep going!",
  "You've got this!",
  "Nice work!",
  "Sometimes later becomes never, DO IT NOW!",
  "Don’t wait for opportunity. Create it.",
  "a year from now you may wish you had started today",
  "If you cannot do great things, do small things in a great way.",
  "It is never too late to be what you might have been.",
  "Just keep going. Everybody gets better if they keep at it.",
  "Believe you can and you're halfway there.",
  "You don't have to be great to start, but you have to start to be great.",
  "Start where you are, use what you have, do what you can",
  "Aim for the moon. If you miss, you may hit a star.",
  "Don’t watch the clock; do what it does. Keep going.",
  "One way to keep momentum going is to have constantly greater goals.",
  "You can do it!",
  "The most difficult thing is the decision to act, the rest is merely tenacity.",
  "the secret of getting ahead is getting started.",
  "Never give up.",
  "A little progress every day adds up to big results.",
  "Take a small step everyday."
];
