import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Vote = ({ votable }) => {
  const [vote, setVote] = useState(toVote(votable.likes));

  const upvoteCallback = useCallback(() => upvote(setVote, votable), [votable]);
  const downvoteCallback = useCallback(() => downvote(setVote, votable), [
    votable
  ]);

  const voting = localStorage.getItem("voting") === "true";
  let upvoteStyle, downvoteStyle;
  let orangeColor = "orange";
  let purpleColor = "purple";
  if (voting !== true) {
    upvoteStyle = { color: "gray" };
    downvoteStyle = { color: "gray" };
    orangeColor = "#cc5200";
    purpleColor = "#7193ff";
  }

  if (vote === 1) {
    upvoteStyle = { color: orangeColor };
  } else if (vote === -1) {
    downvoteStyle = { color: purpleColor };
  }

  const votingMessage = voting !== true ? "Unable to vote" : "";
  return (
    <div className="voting" title={votingMessage}>
      <FontAwesomeIcon
        onClick={() => voting && upvoteCallback()}
        icon="arrow-up"
        style={upvoteStyle}
        tabIndex="0"
      ></FontAwesomeIcon>
      <span>{votable.score}</span>
      <FontAwesomeIcon
        onClick={() => voting && downvoteCallback()}
        icon="arrow-down"
        style={downvoteStyle}
        tabIndex="0"
      ></FontAwesomeIcon>
    </div>
  );
};

function toVote(vote) {
  switch (vote) {
    case true:
      return 1;

    case false:
      return -1;

    default:
      return 0;
  }
}

function upvote(setVote, votable) {
  votable.score -= toVote(votable.likes);
  if (votable.likes === true) {
    votable.unvote().then(() => {
      votable.likes = null;
      setVote(0);
      console.log(
        `votable with id ${votable.id} had upvote retracted with user consent!`
      );
    });
  } else {
    votable.upvote().then(() => {
      votable.likes = true;
      votable.score++;
      setVote(1);
      console.log(
        `votable with id ${votable.id} has been upvoted with user consent!`
      );
    });
  }
}

function downvote(setVote, votable) {
  votable.score -= toVote(votable.likes);
  if (votable.likes === false) {
    votable.unvote().then(() => {
      votable.likes = null;
      setVote(0);
      console.log(
        `votable with id ${votable.id} had downvote retracted with user consent!`
      );
    });
  } else {
    votable.downvote().then(() => {
      votable.likes = false;
      votable.score--;
      setVote(-1);
      console.log(
        `votable with id ${votable.id} has been downvoted with user consent!`
      );
    });
  }
}

export default Vote;
