export const SET_NSFW = "SET_NSFW";
export const SET_THEME = "SET_THEME";
export const SET_DELETE = "SET_DELETE";
export const SET_WAIT = "SET_WAIT";

export function setNSFW(nsfw) {
  return dispatch => {
    if (typeof nsfw !== "boolean") {
      console.warn(
        "Action setNSFW did not run because the parameters were invalid."
      );
      return;
    }

    localStorage.setItem("nsfw_enabled", nsfw);

    dispatch({
      type: SET_NSFW,
      payload: nsfw
    });
  };
}

export function setTheme(theme) {
  return dispatch => {
    if (theme !== "dark" && theme !== "light") {
      console.warn(
        "Action setTheme did not run because the parameters were invalid."
      );
      return;
    }

    localStorage.setItem("theme", theme);
    localStorage.setItem("auto_change", "false");

    dispatch({
      type: SET_THEME,
      payload: theme
    });
  };
}

export function setDelete(delete_enabled) {
  return dispatch => {
    if (typeof delete_enabled !== "boolean") {
      console.warn(
        "Action setDelete did not run because the parameters were invalid."
      );
      return;
    }

    localStorage.setItem("delete_enabled", delete_enabled);

    dispatch({
      type: SET_DELETE,
      payload: delete_enabled
    });
  };
}

export function setWait(wait) {
  localStorage.setItem("wait_time", wait);

  return {
    type: SET_WAIT,
    payload: wait
  };
}
