import * as actions from "./previewActions.js";

const defaultState = {
  torPost: null,
  post: null,
  subRules: {
    rules: []
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.GET_LINKED_POST: {
      return { ...state, torPost: action.payload };
    }

    case actions.GET_LINKED_POST_FULFILLED: {
      return { ...state, post: action.payload };
    }

    case actions.GET_LINKED_POST_REJECTED: {
      return { ...state, postError: action.error };
    }

    default:
      return state;
  }
};
