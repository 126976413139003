import snoowrap from "snoowrap";

export const LOGIN = "LOGIN";

export const GET_REDDIT = "GET_REDDIT";
export const SET_REDDIT = "SET_REDDIT";

export const SET_AUTHENTICATION_URL = "SET_AUTHENTICATION_URL";

export const REDIRECT = "REDIRECT";
export const REDIRECT_PENDING = `${REDIRECT}_PENDING`;
export const REDIRECT_FULFILLED = `${REDIRECT}_FULFILLED`;

export const CHANGE_THEME = "CHANGE_THEME";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_COC = "SET_COC";
export const SET_LOADING = "SET_LOADING";

export const GET_RULES = "GET_RULES";
export const GET_RULES_FULFILLED = `${GET_RULES}_FULFILLED`;
export const GET_RULES_REJECTED = `${GET_RULES}_REJECTED`;

export function loadApp() {
  return dispatch => {
    dispatch(getRefreshToken());
    dispatch(getReddit());
    dispatch(setLoading());
  };
}

export function getReddit() {
  return (dispatch, getState) => {
    dispatch({ type: GET_REDDIT });

    const refreshToken = getState().globals.refreshToken;

    const clientID =
      process.env.NODE_ENV === "production"
        ? "DTam2q-mIcdJNQ"
        : "7ozm1DAE913NgQ";
    const reddit = new snoowrap({
      userAgent: "not-tor-app-web v0.1 (by /u/ILikePokemonGo101)",
      clientId: clientID,
      clientSecret: "",
      refreshToken: refreshToken
    });

    reddit.config({ debug: true });

    return dispatch(setReddit(reddit));
  };
}

export function setReddit(reddit) {
  return {
    type: SET_REDDIT,
    payload: reddit
  };
}

export function getRefreshToken() {
  return dispatch => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken !== "" && refreshToken != null) {
      dispatch(setRefreshToken(refreshToken));
    }
  };
}

export function login(history) {
  return dispatch => {
    dispatch({ type: LOGIN });
    dispatch(redirectTo(history, "/logged-in"));
  };
}

export function setAuthenticationURL(url) {
  return {
    type: SET_AUTHENTICATION_URL,
    payload: url
  };
}

export function setLoading(loading = false) {
  return dispatch => {
    if (typeof loading !== "boolean") {
      console.warn(
        "Action setLoading did not run because the parameters were invalid."
      );
      return;
    }

    dispatch({ type: SET_LOADING, payload: loading });
  };
}

// Redirects to the url after a delay in seconds.
export function redirectTo(history, url, delay = 0) {
  return dispatch => {
    dispatch({ type: REDIRECT, payload: { redirectTo: url } });
    dispatch({ type: REDIRECT_PENDING });
    window.setTimeout(() => {
      history.push(url);
      dispatch({ type: REDIRECT_FULFILLED });
    }, delay * 1000);
  };
}

export function setRefreshToken(refreshToken) {
  localStorage.setItem("refresh_token", refreshToken);

  return {
    type: SET_REFRESH_TOKEN,
    payload: refreshToken
  };
}

export function setCoC(coc) {
  return dispatch => {
    if (typeof coc !== "boolean") {
      console.warn(
        "Action setCoC did not run because the parameters were invalid."
      );
      return;
    }

    localStorage.setItem("coc_agreed", coc);

    dispatch({
      type: SET_COC,
      payload: coc
    });
  };
}
